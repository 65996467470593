export const PAGE_TITLE_BASE = 'Out-of-Province COVID-19 Vaccine Entry Service';
export const PAGINATION_LIMIT = 20;
export const REPEATER_ITEM_LIMIT = 5;
export const BLANK_VALUE = '–';
export const INPUT_MAX_LENGTH = 100;
export const TEXTAREA_MAX_LENGTH = 100;
export const NUMBER_MAX_LENGTH = 10;
export const MESSAGE_MAX_LENGTH = 500;
export const MULTI_SELECT_MAX_OPTIONS = 5;
export const DOC_TYPE_GOV_ID = 'GOVERNMENT_ID';
export const DOC_TYPE_VACCINE_CONFIRM = 'VACCINE_CONFIRMATION';
export const DOC_CONTENT_TYPE_DOCX = 'DOCX';
export const DOC_CONTENT_TYPE_PDF = 'PDF';
export const DOC_CONTENT_TYPE_PNG = 'PNG';
export const DOC_CONTENT_TYPE_JPEG = 'JPEG';
export const DOC_CONTENT_TYPE_TEXT = 'TEXT';
export const DOC_CONTENT_TYPE_DOC = 'DOC';
export const DOC_CONTENT_TYPE_XLS = 'XLS';
export const DOC_CONTENT_TYPE_XLSX = 'XLSX';
export const DOC_CONTENT_TYPE_MSG = 'MSG';
export const DOC_MIME_TYPE_PDF = 'application/pdf';
export const DOC_MIME_TYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const DOC_MIME_TYPE_PNG = 'image/png';
export const DOC_MIME_TYPE_JPEG = 'image/jpeg';
export const DOC_MIME_TYPE_TEXT = 'text/plain';
export const DOC_MIME_TYPE_DOC = 'application/msword';
export const DOC_MIME_TYPE_XLS = 'application/vnd.ms-excel';
export const DOC_MIME_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const DOC_MIME_TYPE_MSG = 'application/vnd.ms-outlook';
export const DOC_CONTENT_TYPES = [
	DOC_CONTENT_TYPE_PDF,
	DOC_CONTENT_TYPE_PNG,
	DOC_CONTENT_TYPE_JPEG
];
export const DOC_MIME_TYPES = [
	DOC_MIME_TYPE_PDF,
	DOC_MIME_TYPE_PNG,
	DOC_MIME_TYPE_JPEG
];
export const DOC_MAX_FILESIZE = 5000000;
export const DOC_MAX_FILENAME = 75;
export const DOC_MAX_QUANTITY = 5;
export const INACTIVITY_TIMEOUT = 1800000;
export const LANGUAGE_ENGLISH = 'ENGLISH';
export const LANGUAGE_ENGLISH_LABEL = 'English';
export const LANGUAGE_ENGLISH_CODE = 'en';
export const LANGUAGE_FRENCH = 'FRENCH';
export const LANGUAGE_FRENCH_LABEL = 'Français';
export const LANGUAGE_FRENCH_CODE = 'fr';
export const LANGUAGE_MAPPINGS = [
	{ name: LANGUAGE_ENGLISH, code: LANGUAGE_ENGLISH_CODE },
	{ name: LANGUAGE_FRENCH, code: LANGUAGE_FRENCH_CODE }
];
