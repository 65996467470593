import { store } from '../store';

export function authHeader() {
	const state = store.getState();

	let authHeader = {};
	if (state && state.authenticate && state.authenticate.success) {
		authHeader = { 'Authorization': 'Bearer ' + state.authenticate.data.jwttoken };
	}

	return authHeader;
}
