import { applicationConstants } from '../constants/applicationConstants';

export function currentApplication(state = { changeType: 'SAVE' }, action) {
	switch (action.type) {
		case applicationConstants.CURRENT_APPLICATION_UPDATE:
			return action.data;
		case applicationConstants.CURRENT_APPLICATION_RESET:
			return {};
		default:
			return state;
	}
}

export function postApplication(state = {}, action) {
	switch (action.type) {
		case applicationConstants.APPLICATION_POST_REQUEST:
			return {
				request: true
			};
		case applicationConstants.APPLICATION_POST_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case applicationConstants.APPLICATION_POST_ERROR:
			return {
				error: true
			};
		case applicationConstants.APPLICATION_POST_RESET:
			return {};
		default:
			return state;
	}
}

export function applicationReview(state = false, action) {
	switch (action.type) {
		case applicationConstants.APPLICATION_REVIEW:
			return true;
		case applicationConstants.APPLICATION_REVIEW_RESET:
			return false;
		default:
			return state;
	}
}
