import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { scrollUp } from '../../../helpers/miscHelpers';
import { setHasChanges } from '../../../store/actions/globalMessagesActions';
import { applicationActions } from '../../../store/actions/applicationActions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Cancel from '../../misc/Cancel';
import BehalfInfo from '../BehalfInfo';

function Intro(props) {
	const dispatch = useDispatch();

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const currentApplication = useSelector(state => state.currentApplication);

	const [validated, setValidated] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const formRef = useRef(null);

	const {
		behalfSomeoneElse,
		sixteenAndOlderBehalf
	} = currentApplication;

	// Listening to current application data updates
	useEffect(() => {
		if (validated) {
			setHasErrors(!formRef.current.checkValidity());
		}
	}, [currentApplication]);

	const handleUpdate = (id, value) => {
		let fieldVal;

		if (value == 'yes') {
			fieldVal = true;
		} else if (value == 'no') {
			fieldVal = false;
		} else {
			fieldVal = value;
		}

		const updatedData = {
			...currentApplication,
			[id]: fieldVal
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			setHasErrors(true);
			event.preventDefault();
			event.stopPropagation();
		} else {
			setValidated(false);
			setHasErrors(false);

			if (!sixteenAndOlderBehalf) {
				dispatch(setHasChanges(false));
				setTimeout(() => {
					window.location.replace('/do-not-qualify');
				}, 150);
			} else {
				if (props.handleProceed) {
					props.handleProceed();
				}
				scrollUp();
			}
		}

		setValidated(true);
	};

	return (
		<div id="intro">
			<Row>
				<Col md={6}>
					<Button
						href="/"
						variant="link"
						id="btn-intro-back"
						className="mb-2"
					>
						<Translate text="Back" />
					</Button>

					<h1><Translate text="Out-of-Province COVID-19 Vaccine Entry Service" /></h1>

					<h3><Translate text="Nova Scotians can now add COVID-19 vaccines that they received outside the province or through a workplace vaccination program to their Nova Scotia proof of vaccination." /></h3>

					<p><Translate text="The opportunity is available to:" /></p>

					<ul>
						<li><Translate text="permanent residents of Nova Scotia who received one or both doses in another province, territory or country" /></li>

						<li><Translate text="permanent residents who received one or both doses in a workplace vaccination program, such as members of the Canadian Armed Forces" /></li>

						<li><Translate text="people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card" /></li>
					</ul>

					<h2 className="mt-5"><Translate text="Privacy notice" /></h2>

					<p className="mb-5">
						<Translate
							text="The personal information you provide online for the Out of Province Vaccine Entry Service is collected under the authority of sections 8, 15 and 50 of the <Link>. The submitted personal information via this form is subject to the Department of Health and Wellness and Government of Nova Scotia’s security and privacy policies. The collected personal information will only be used to facilitate eligible users of this service who vaccinated outside Nova Scotia to provide provincially issued proof of vaccination, unless otherwise authorized by law or your express consent. Any secondary use without express consent is limited to aggregate, de-identified information."
							renderMap={{
								renderLink: () => <a href="https://nslegislature.ca/sites/default/files/legc/statutes/health%20protection.pdf" target="_blank" rel="noopener noreferrer"><Translate text="Health Protection Act (PDF)" /></a> // eslint-disable-line react/display-name
							}}
						/>
					</p>

					<Translator>
						{({ translate }) => (
							<Form
								ref={ formRef }
								noValidate
								validated={ validated }
								onSubmit={ handleSubmit }
							>
								<Form.Group controlId="sixteenAndOlderBehalf">
									<Form.Label>
										<Translate text="Are you 16 or older?" /><span className="required">*</span>
									</Form.Label>
									<Form.Control
										as="select"
										name="sixteenAndOlderBehalf"
										value={ (sixteenAndOlderBehalf == true) ? 'yes' : (sixteenAndOlderBehalf == false && sixteenAndOlderBehalf !== '') ? 'no' : '' }
										required
										onChange={(e) => { handleUpdate(e.target.name, e.target.value); }}
									>
										<option value="">
											{ translate({ text: '- Select -' }) }
										</option>
										<option value="yes">
											{ translate({ text: 'Yes' }) }
										</option>
										<option value="no">
											{ translate({ text: 'No' }) }
										</option>
									</Form.Control>
									<Form.Control.Feedback type="invalid" className="error-sixteen-and-older">
										<Translate text="This is a required field." />
									</Form.Control.Feedback>
								</Form.Group>

								{ (sixteenAndOlderBehalf == true || sixteenAndOlderBehalf == 'yes') &&
									<Form.Group controlId="behalfSomeoneElse">
										<Form.Label>
											<Translate text="Are you completing this form on behalf of someone else?" /><span className="required">*</span>
										</Form.Label>
										<Form.Control
											as="select"
											name="behalfSomeoneElse"
											value={ (behalfSomeoneElse == true) ? 'yes' : (behalfSomeoneElse == false && behalfSomeoneElse !== '') ? 'no' : '' }
											required
											onChange={(e) => { handleUpdate(e.target.name, e.target.value); }}
										>
											<option value="">
												{ translate({ text: '- Select -' }) }
											</option>
											<option value="yes">
												{ translate({ text: 'Yes' }) }
											</option>
											<option value="no">
												{ translate({ text: 'No' }) }
											</option>
										</Form.Control>
										<Form.Control.Feedback type="invalid" className="error-on-behalf">
											<Translate text="This is a required field." />
										</Form.Control.Feedback>
									</Form.Group>
								}

								{ (sixteenAndOlderBehalf == true || sixteenAndOlderBehalf == 'yes') && behalfSomeoneElse &&
									<>
										<h2><Translate text="Your Information" /></h2>

										<BehalfInfo validated={ validated } />
									</>
								}

								<ButtonToolbar className="pt-2">
									<Button type="submit" id="btn-intro-continue">
										<Translate text="Continue" />
									</Button>

									<Cancel />
								</ButtonToolbar>

								{ (validated && hasErrors) &&
									<div className="invalid-feedback show error-summary-message" id="error-summary-intro">
										<p><Translate text="One or more fields are missing or incorrect" /></p>
									</div>
								}
							</Form>
						)}
					</Translator>
				</Col>
			</Row>
		</div>
	);
}

export default Intro;
