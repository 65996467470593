import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authenticate as authenticateReducer } from './reducers/authenticateReducer';
import {
	globalMessages as globalMessagesReducer,
	hasChanges as hasChangesReducer
} from './reducers/globalMessagesReducer';
import { referenceData as referenceDataReducer } from './reducers/referenceDataReducer';
import { language as languageReducer } from './reducers/languageReducer';
import {
	currentApplication as currentApplicationReducer,
	postApplication as postApplicationReducer,
	applicationReview as applicationReviewReducer
} from './reducers/applicationReducer';

const reducers = combineReducers({
	authenticate: authenticateReducer,
	globalMessages: globalMessagesReducer,
	hasChanges: hasChangesReducer,
	referenceData: referenceDataReducer,
	language: languageReducer,
	currentApplication: currentApplicationReducer,
	postApplication: postApplicationReducer,
	applicationReview: applicationReviewReducer
});

const persistConfig = {
	key: 'root',
	whitelist: [
		'language'
	],
	storage
};

const persistedReducer = persistReducer(persistConfig, (state, action) => reducers(state, action));

export const store = createStore(
	persistedReducer,
	applyMiddleware(
		thunkMiddleware
	)
);

export const persistor = persistStore(store);
