import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import NumberFormat from 'react-number-format';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';

import Form from 'react-bootstrap/Form';
import FormDate from '../forms/FormDate';
import FormEmail from '../forms/FormEmail';
import FormName from '../forms/FormName';

function ApplicantInfo(props) {
	const { validated } = props;
	const { INPUT_MAX_LENGTH } = require('../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const referenceData = useSelector(state => state.referenceData);
	const currentApplication = useSelector(state => state.currentApplication);

	const [genderOptions, setGenderOptions] = useState([]);

	const {
		firstName,
		lastName,
		gender,
		birthDate,
		emailAddress,
		emailAddressConfirm,
		phoneNumber
	} = currentApplication;

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data && referenceData.data.genderDataList) {
			setGenderOptions(referenceData.data.genderDataList);
		}
	}, [referenceData]);

	const handleCheckValidity = (event) => {
		if (!event.target.checkValidity()) {
			event.target.closest('.form-group').classList.add('was-validated');
		} else {
			event.target.closest('.form-group').classList.remove('was-validated');
		}
	};

	const handleUpdate = (id, value) => {
		const updatedData = {
			...currentApplication,
			[id]: value
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	return (
		<Translator>
			{({ translate }) => (
				<>
					<FormName
						id="firstName"
						name="firstName"
						value={ firstName }
						labelText={ translate({ text: 'First name' }) }
						customError={ translate({ text: 'Enter a first name' }) }
						required
						handleOnBlur={(id, value) => { handleUpdate(id, value); }}
					/>

					<FormName
						id="lastName"
						name="lastName"
						value={ lastName }
						labelText={ translate({ text: 'Last name' }) }
						customError={ translate({ text: 'Enter a last name' }) }
						required
						handleOnBlur={(id, value) => { handleUpdate(id, value); }}
					/>

					<Form.Group controlId="gender">
						<Form.Label>
							<Translate text="Gender at birth" /><span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="gender"
							value={ (gender) ? gender : '' }
							required
							disabled={ !genderOptions || !genderOptions.length }
							onChange={(e) => { handleUpdate(e.target.name, e.target.value); }}
							onBlur={(e) => { handleCheckValidity(e); }}
						>
							<option value="">
								{ translate({ text: '- Select -' }) }
							</option>

							{ (Array.isArray(genderOptions)) &&
								genderOptions.map((item, index) => {
									return (
										<option value={ item.description } key={ index }>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid" className="error-gender">
							<Translate text="Select your gender at birth" />
						</Form.Control.Feedback>
					</Form.Group>

					<FormDate
						id="birthDate"
						name="birthDate"
						label={ translate({ text: 'Date of birth' }) }
						value={ birthDate }
						required={ true }
						validated={ validated }
						customError={ translate({ text: 'Enter a date of birth' }) }
						handleOnChange={(id, value) => handleUpdate(id, value) }
					/>

					<FormEmail
						id="emailAddress"
						name="emailAddress"
						value={ emailAddress }
						confirmValue={ emailAddressConfirm }
						required
						handleOnChange={(id, value) => { handleUpdate(id, value); }}
					/>

					<Form.Group controlId="phone">
						<Form.Label>
							<Translate text="Phone number" /><span className="required">*</span>
						</Form.Label>
						<NumberFormat
							name="phoneNumber"
							value={ phoneNumber }
							customInput={ Form.Control }
							type="tel"
							maxLength={ INPUT_MAX_LENGTH }
							format="###-###-####"
							placeholder="###-###-####"
							mask="_"
							required
							onBlur={(e) => { handleUpdate(e.target.name, e.target.value); handleCheckValidity(e); }}
						/>
						<Form.Control.Feedback type="invalid" className="error-phone-number">
							<Translate text="Phone number is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols." />
						</Form.Control.Feedback>
					</Form.Group>
				</>
			)}
		</Translator>
	);
}

export default ApplicantInfo;
