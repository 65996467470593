import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-translated';
import { format, parseISO } from 'date-fns';

import { getNumberWithOrdinal } from '../../../helpers/miscHelpers';
import { applicationActions } from '../../../store/actions/applicationActions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Cancel from '../../misc/Cancel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Review(props) {
	const {
		validated,
		hasErrors
	} = props;

	const {
		BLANK_VALUE,
		DOC_TYPE_GOV_ID,
		DOC_TYPE_VACCINE_CONFIRM
	} = require('../../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const currentApplication = useSelector(state => state.currentApplication);
	const postApplication = useSelector(state => state.postApplication);

	const [showError, setShowError] = useState(false);
	const [showAdditionalConsent, setShowAdditionalConsent] = useState(false);
	const additionalConsentValues = [
		'Child',
		'Foster Parent',
		'Step Parent',
		'Parent of a child over 16'
	];

	// App is now in review.
	useEffect(() => {
		dispatch(applicationActions.applicationReview());
	}, []);

	// Listen to current application data.
	useEffect(() => {
		if (currentApplication.relationshipOther || (currentApplication.relationshipToApplicant && additionalConsentValues.includes(currentApplication.relationshipToApplicant))) {
			setShowAdditionalConsent(true);
		} else {
			setShowAdditionalConsent(false);
		}
	}, [currentApplication]);

	// Listening to submission post.
	useEffect(() => {
		if (postApplication.success) {
			setShowError(false);
		} else if (postApplication.error) {
			setShowError(true);
		}
	}, [postApplication]);

	const handleEdit = (num) => {
		if (props.handleEdit) {
			props.handleEdit(num);
		}
	};

	const handleConsentChange = (event) => {
		const { checked } = event.target;
		let val;

		if (checked) {
			val = true;
		} else {
			val = false;
		}

		const updatedData = {
			...currentApplication,
			consent: val
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));
	};

	return (
		<div id="review-info">
			<Row className="align-items-center">
				<Col sm={9}>
					<h1><Translate text="Proof of Vaccination - Review your information" /></h1>
				</Col>
			</Row>

			{ currentApplication &&
				<Row>
					<Col sm={9}>
						{ currentApplication.behalfSomeoneElse &&
							<>
								<h2 className="d-flex">
									<Translate text="Your Information" />
									<Button
										id="btn-review-your-edit"
										variant="link"
										className="ml-3"
										onClick={() => handleEdit(0) }
									>
										<Translate text="Edit" />
									</Button>
								</h2>
								<ListGroup variant="flush">
									<ListGroup.Item>
										<div className="list-group-item-col">
											<strong><Translate text="First name" /></strong>
										</div>
										<div className="list-group-item-col">
											{ currentApplication.firstNameBehalf }
										</div>
									</ListGroup.Item>
									<ListGroup.Item>
										<div className="list-group-item-col">
											<strong><Translate text="Last name" /></strong>
										</div>
										<div className="list-group-item-col">
											{ currentApplication.lastNameBehalf }
										</div>
									</ListGroup.Item>
									<ListGroup.Item>
										<div className="list-group-item-col">
											<strong><Translate text="Date of birth" /></strong>
										</div>
										<div className="list-group-item-col">
											{ (currentApplication.birthDateBehalf) ? format(parseISO(currentApplication.birthDateBehalf), 'MMM d, yyyy') : BLANK_VALUE }
										</div>
									</ListGroup.Item>
									<ListGroup.Item>
										<div className="list-group-item-col">
											<strong><Translate text="Email address" /></strong>
										</div>
										<div className="list-group-item-col">
											{ currentApplication.emailAddressBehalf }
										</div>
									</ListGroup.Item>
									<ListGroup.Item>
										<div className="list-group-item-col">
											<strong><Translate text="Phone number" /></strong>
										</div>
										<div className="list-group-item-col">
											{ currentApplication.phoneNumberBehalf }
										</div>
									</ListGroup.Item>
								</ListGroup>
							</>
						}

						<h2 className="d-flex">
							<Translate text="Applicant Information" />
							<Button
								id="btn-review-applicant-edit"
								variant="link"
								className="ml-3"
								onClick={() => handleEdit(2) }
							>
								<Translate text="Edit" />
							</Button>
						</h2>
						<ListGroup variant="flush">
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="First name" /></strong>
								</div>
								<div className="list-group-item-col">
									{ currentApplication.firstName }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Last name" /></strong>
								</div>
								<div className="list-group-item-col">
									{ currentApplication.lastName }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Gender at birth" /></strong>
								</div>
								<div className="list-group-item-col">
									{ currentApplication.gender }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Date of birth" /></strong>
								</div>
								<div className="list-group-item-col">
									{ (currentApplication.birthDate) ? format(parseISO(currentApplication.birthDate), 'MMM d, yyyy') : BLANK_VALUE }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Email address" /></strong>
								</div>
								<div className="list-group-item-col">
									{ currentApplication.emailAddress }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Phone number" /></strong>
								</div>
								<div className="list-group-item-col">
									{ currentApplication.phoneNumber }
								</div>
							</ListGroup.Item>
						</ListGroup>

						<h2 className="d-flex">
							<Translate text="Applicant ID" />
							<Button
								id="btn-review-applicant-id-edit"
								variant="link"
								className="ml-3"
								onClick={() => handleEdit(2) }
							>
								<Translate text="Edit" />
							</Button>
						</h2>
						<ListGroup variant="flush">
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Health Card type" /></strong>
								</div>
								<div className="list-group-item-col">
									{ currentApplication.healthCardType }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Health Card number" /></strong>
								</div>
								<div className="list-group-item-col">
									{ (currentApplication.healthCardNumber) ? currentApplication.healthCardNumber : BLANK_VALUE }
								</div>
							</ListGroup.Item>
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Government ID" /></strong>
								</div>
								<div className="list-group-item-col">
									{ Array.isArray(currentApplication.vaccinationDocumentList) &&
										currentApplication.vaccinationDocumentList.filter(item => item.documentType == DOC_TYPE_GOV_ID).map(item => { return item.filename; }).join(', ')
									}
								</div>
							</ListGroup.Item>
						</ListGroup>

						<h2 className="d-flex">
							<Translate text="Vaccine Information" />
							<Button
								id="btn-review-vaccine-edit"
								variant="link"
								className="ml-3"
								onClick={() => handleEdit(3) }
							>
								<Translate text="Edit" />
							</Button>
						</h2>

						{ Array.isArray(currentApplication.doseAdministeredDataList) &&
							<>
								{ currentApplication.doseAdministeredDataList.map((item, index) => {
									return (
										<React.Fragment key={ index }>
											{ (currentApplication.doseAdministeredDataList.length > 1) &&
												<h3>
													<Translate
														text="{ordinal} Dose"
														data={{
															ordinal: getNumberWithOrdinal(index + 1)
														}}
													/>
												</h3>
											}

											<ListGroup variant="flush">
												<ListGroup.Item>
													<div className="list-group-item-col">
														<strong><Translate text="Where was the dose administered?" /></strong>
													</div>
													<div className="list-group-item-col">
														{ item.doseCountry }
													</div>
												</ListGroup.Item>
												<ListGroup.Item>
													<div className="list-group-item-col">
														<strong><Translate text="What date was the dose administered?" /></strong>
													</div>
													<div className="list-group-item-col">
														{ (item.doseDate) ? format(parseISO(item.doseDate), 'MMM d, yyyy') : BLANK_VALUE }
													</div>
												</ListGroup.Item>
												<ListGroup.Item>
													<div className="list-group-item-col">
														<strong><Translate text="What was the name of the vaccine that was administered?" /></strong>
													</div>
													<div className="list-group-item-col">
														{ item.vaccineName }
													</div>
												</ListGroup.Item>
												<ListGroup.Item>
													<div className="list-group-item-col">
														<strong><Translate text="What was the lot number of the vaccine?" /></strong>
													</div>
													<div className="list-group-item-col">
														{ (item.lotNumber) ? item.lotNumber : BLANK_VALUE }
													</div>
												</ListGroup.Item>
											</ListGroup>
										</React.Fragment>
									);
								})}
							</>
						}

						<h3>
							<Translate text="Vaccine Confirmation" />
						</h3>
						<ListGroup variant="flush">
							<ListGroup.Item>
								<div className="list-group-item-col">
									<strong><Translate text="Vaccination Record(s)" /></strong>
								</div>
								<div className="list-group-item-col">
									{ Array.isArray(currentApplication.vaccinationDocumentList) &&
										currentApplication.vaccinationDocumentList.filter(item => item.documentType == DOC_TYPE_VACCINE_CONFIRM).map(item => { return item.filename; }).join(', ')
									}
								</div>
							</ListGroup.Item>
						</ListGroup>

						<h2><Translate text="Confirmation and consent" /></h2>
						<p><Translate text="I understand the purpose of this information collection, use and disclosure and I declare that the information given on this form is complete, correct and fully discloses everything concerning my vaccination out of Nova Scotia." /></p>

						{ showAdditionalConsent &&
							<p><Translate text="If you are completing this form on behalf of another individual over the age of 16, please confirm by checking this box that you have the consent of the applicant for submitting their information." /></p>
						}

						<Form.Group>
							<div className="form-check-big max-width-none form-check form-check-inline">
								<Form.Check.Input
									name="consent-submission"
									id="consent-submission"
									className="form-check-big max-width-none"
									type="checkbox"
									value="1"
									required
									onChange={ handleConsentChange }
								/>
								<Form.Check.Label htmlFor="consent-submission">
									<Translate text="I confirm and consent" /><span className="required">*</span>
								</Form.Check.Label>
							</div>
						</Form.Group>
					</Col>
				</Row>
			}

			<Row>
				<Col sm={9}>
					{ showError &&
						<Alert variant="danger" onClose={() => setShowError(false)} dismissible>
							<span className="alert-icon">
								<FontAwesomeIcon icon={ faExclamationCircle } />
							</span>
							<span className="alert-message">
								<Translate text="Error: Application could not be submitted. Please try again later." />
							</span>
						</Alert>
					}

					<ButtonToolbar className="pt-2">
						<Button
							id="btn-review-submit"
							type="submit"
							variant="success"
						>
							<Translate text="Submit" />
						</Button>

						<Cancel />
					</ButtonToolbar>

					{ (validated && hasErrors) &&
						<div className="invalid-feedback show error-summary-message" id="error-summary-review">
							<p><Translate text="One or more fields are missing or incorrect" /></p>
						</div>
					}
				</Col>
			</Row>
		</div>
	);
}

export default Review;
