import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import * as globalMessagesActions from '../../store/actions/globalMessagesActions';
import { fileService } from '../../services/fileService';
import { base64toBlob } from '../../helpers/fileHelpers';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

function SavePDF(props) {
	const { authDocumentKey } = props;
	const dispatch = useDispatch();

	if (!authDocumentKey) {
		return null;
	}

	const [isDownloading, setIsDownloading] = useState(false);

	const handleFileDownload = async (event, authDocumentKey) => {
		event.preventDefault();

		if (!isDownloading) {
			setIsDownloading(true);

			await fileService.get(authDocumentKey)
				.then(
					response => {
						if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
							if (window.navigator.msSaveBlob) {
								const blob = base64toBlob(response.file);
								window.navigator.msSaveOrOpenBlob(blob, response.filename);
							} else {
								setIsDownloading(false);
								const blob = base64toBlob(response.file);
								const downloadLink = document.createElement('a');
								downloadLink.href = window.URL.createObjectURL(blob);
								downloadLink.download = response.filename;
								downloadLink.click();
							}
						} else {
							dispatch(globalMessagesActions.error('Error: File could not be downloaded.'));
						}
					}
				);

			setIsDownloading(false);
		}
	};

	return (
		<Button
			id="btn-success-save"
			className="d-print-none save-pdf"
			onClick={(e) => handleFileDownload(e, authDocumentKey)}
		>
			{ isDownloading &&
				<Spinner animation="border" role="status" className="loader" />
			}

			{ !isDownloading &&
				<FontAwesomeIcon icon={ faSave } />
			}

			<Translate text="Save a copy" />
		</Button>
	);
}

export default SavePDF;
