import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';
import { validateHealthNumber } from '../../helpers/miscHelpers';

import Form from 'react-bootstrap/Form';

function HealthCardDetails(props) {
	const { validated } = props;
	const dispatch = useDispatch();

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const referenceData = useSelector(state => state.referenceData);
	const currentApplication = useSelector(state => state.currentApplication);

	const healthCardInputRef = useRef(false);

	const [typeOptions, setTypeOptions] = useState([]);
	const [isValidNumber, setIsValidNumber] = useState(false);
	const numberDisableList = [
		'International Student',
		'Corrections',
		'RCMP',
		'Canadian Forces',
		'Veteran Affairs',
		'Out of Country',
		'Other'
	];

	const {
		healthCardType,
		healthCardNumber
	} = currentApplication;

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data && referenceData.data.hcnTypeDataList) {
			setTypeOptions(referenceData.data.hcnTypeDataList);
		}
	}, [referenceData]);

	useEffect(() => {
		if (healthCardNumber && healthCardType && healthCardInputRef.current) {
			handleValidateNumber({ target: healthCardInputRef.current });
			handleCheckValidity({ target: healthCardInputRef.current });
		}
	}, [healthCardType, healthCardNumber]);

	const handleCheckValidity = (event) => {
		if (!event.target.checkValidity()) {
			event.target.closest('.form-group').classList.add('was-validated');
		} else {
			event.target.closest('.form-group').classList.remove('was-validated');
		}
	};

	const handleUpdate = (id, value) => {
		let updatedData;

		if (id == 'healthCardType' && numberDisableList.includes(value)) {
			updatedData = {
				...currentApplication,
				'healthCardType': value,
				'healthCardNumber': ''
			};
		} else {
			updatedData = {
				...currentApplication,
				[id]: value
			};
		}

		dispatch(applicationActions.currentApplicationUpdate(updatedData));

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleValidateNumber = (event) => {
		const value = healthCardNumber;

		if (value) {
			const isValid = validateHealthNumber(value, healthCardType);

			if (isValid) {
				setIsValidNumber(true);
				event.target.setCustomValidity('');
			} else {
				setIsValidNumber(false);
				event.target.setCustomValidity('Enter a valid health card number');
			}
		} else {
			setIsValidNumber(false);
			event.target.setCustomValidity('Enter a valid health card number');
		}
	};

	return (
		<Translator>
			{({ translate }) => (
				<>
					<h3><Translate text="Canadian Health Card number" /></h3>

					<p><Translate text={'Select your Health Card type. If you do not have a Canadian Health Card, select one of the other options that best applies to you.'} /></p>

					<Form.Group controlId="healthCardType">
						<Form.Label>
							<Translate text="Health Card type or alternate ID" /><span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="healthCardType"
							value={ (healthCardType) ? healthCardType : '' }
							required
							disabled={ !typeOptions || !typeOptions.length }
							onChange={(e) => { handleUpdate(e.target.name, e.target.value); }}
							onBlur={(e) => { handleCheckValidity(e); }}
						>
							<option value="">
								{ translate({ text: '- Select -' }) }
							</option>

							{ (Array.isArray(typeOptions)) &&
								typeOptions.map((item, index) => {
									return (
										<option value={ item.description } key={ index }>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid" className="error-health-card-type">
							<Translate text="Select your Heath Card or alternate ID type" />
						</Form.Control.Feedback>
					</Form.Group>

					{ (healthCardType && !numberDisableList.includes(healthCardType)) &&
						<Form.Group controlId="healthCardNumber">
							<Form.Label>
								<Translate text="Health Card number" /><span className="required">*</span>
							</Form.Label>
							<Form.Control
								ref={healthCardInputRef}
								defaultValue={ (healthCardNumber) ? healthCardNumber : '' }
								type="text"
								name="healthCardNumber"
								maxLength={ 12 }
								required
								pattern="[a-zA-Z0-9]+"
								isInvalid={ validated && (!healthCardNumber || !isValidNumber) }
								isValid={ healthCardNumber && isValidNumber}
								onBlur={(e) => { handleUpdate(e.target.name, e.target.value); }}
							/>
							<Form.Control.Feedback type="invalid" className="error-health-card-number">
								<Translate text="Enter a valid health card number" />
							</Form.Control.Feedback>
						</Form.Group>
					}
				</>
			)}
		</Translator>
	);
}

export default HealthCardDetails;
