import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Update from '../update';
import ContentLoading from '../misc/ContentLoading';

function GetStarted() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	const history = useHistory();

	// State data.
	const authenticate = useSelector(state => state.authenticate);

	useEffect(() => {
		document.title = PAGE_TITLE_BASE + ' - Get Started';
	}, []);

	useEffect(() => {
		if (authenticate && authenticate.error) {
			history.replace('/');
		}
	}, [authenticate]);

	return (
		<>
			<Header />

			<ContentLoading>
				<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
					{ authenticate && authenticate.success &&
						<Update />
					}
				</Container>
			</ContentLoading>

			<Footer />
		</>
	);
}

export default GetStarted;
