/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { applicationActions } from '../../../store/actions/applicationActions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Cancel from '../../misc/Cancel';
import VaccineAndDose from '../VaccineAndDose';
import VaccineConfirm from '../VaccineConfirm';

function Three(props) {
	const { validated, hasErrors } = props;
	const dispatch = useDispatch();

	// State data.
	const currentApplication = useSelector((state) => state.currentApplication);
	const data = useSelector((state) => state.referenceData);
	const [numDoses, setNumDoses] = useState(null);
	const numOptions = [
		{ value: 1, name: '1' },
		{ value: 2, name: '2' },
		{ value: 3, name: '3' },
		{ value: 4, name: '4' },
		{ value: 5, name: '5' },
	];

	useEffect(() => {
		if (
			currentApplication &&
			currentApplication.doseAdministeredDataList &&
			currentApplication.doseAdministeredDataList.length
		) {
			setNumDoses(currentApplication.doseAdministeredDataList.length);
		}
	}, [currentApplication]);

	const handleUpdateNum = (value) => {
		setNumDoses(value);

		let items = [];
		let currentItems = Array.isArray(
			currentApplication.doseAdministeredDataList
		)
			? [...currentApplication.doseAdministeredDataList]
			: [];

		currentItems = currentItems.slice(0, value);

		for (let i = 0; i < value; i++) {
			if (currentItems[i]) {
				items.push(currentItems[i]);
			} else {
				items.push({ changeType: 'SAVE', doseNumber: i + 1 });
			}
		}

		const updatedData = {
			...currentApplication,
			doseAdministeredDataList: items,
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));
	};

	const outputFields = () => {
		let groups = [];

		if (numDoses) {
			for (let i = 0; i < numDoses; i++) {
				groups.push(
					<VaccineAndDose
						index={i}
						key={i}
						validated={validated}
						multipleDoses={numDoses > 1}
					/>
				);
			}
		}

		return groups;
	};

	return (
		<Translator>
			{({ translate }) => (
				<div id='step-3'>
					<Row className='align-items-center'>
						<Col sm={8}>
							<h1>
								<Translate text='About Your Vaccine and Dose' />
							</h1>
						</Col>
						<Col sm={4} className='text-sm-right'>
							<h2>
								<Translate text='Step 3 of 3' />
							</h2>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<p>
								<Translate text='Provide the COVID-19 vaccination information received in another province, territory or country or outside a Nova Scotia vaccination clinic (e.g., a community, pharmacy or drive-thru clinic) that should be included on your Nova Scotia COVID-19 Proof of Vaccination.' />
							</p>

							<Form.Group controlId='numDoses'>
								<Form.Label>
									<Translate text='How many doses do you need to add to your Nova Scotia COVID-19 Proof of Vaccination?' />
									<span className='required'>*</span>
								</Form.Label>
								<Form.Control
									as='select'
									name='numDoses'
									value={numDoses ? numDoses : ''}
									required
									onChange={(e) => {
										handleUpdateNum(e.target.value);
									}}
								>
									<option value=''>{translate({ text: '- Select -' })}</option>

									{Array.isArray(numOptions) &&
										numOptions.map((item, index) => {
											return (
												<option value={item.value} key={index}>
													{item.name}
												</option>
											);
										})}
								</Form.Control>
								<Form.Control.Feedback
									type='invalid'
									className='error-doses-number'
								>
									<Translate text='Select the number of doses administered' />
								</Form.Control.Feedback>
							</Form.Group>

							{numDoses && <>{outputFields()}</>}
						</Col>
					</Row>

					{numDoses && (
						<Row>
							<Col md={6}>
								<VaccineConfirm validated={validated} />
							</Col>
							<Col md={6}>
								<div className='box'>
									<p>
										<Translate text='An official proof of the following WHO authorized COVID-19 vaccines:' />
									</p>
									<ul>
										{data.data &&
											data.data.vaccineNameDataList.map((vaccine, index) => (
												<li key={index}>
													<Translate
														text={vaccine.description}
														renderMap={{
															renderTM: () => <sup>&trade;</sup>, // eslint-disable-line react/display-name
														}}
													/>
												</li>
											))}
									</ul>
								</div>
							</Col>
						</Row>
					)}

					<Row>
						<Col md={6}>
							<ButtonToolbar className='pt-2'>
								<Button type='submit' id='btn-step3-continue'>
									<Translate text='Continue' />
								</Button>

								<Cancel />
							</ButtonToolbar>

							{validated && hasErrors && (
								<div
									className='invalid-feedback show error-summary-message'
									id='error-summary-step3'
								>
									<p>
										<Translate text='One or more fields are missing or incorrect' />
									</p>
								</div>
							)}
						</Col>
					</Row>
				</div>
			)}
		</Translator>
	);
}

export default Three;
