import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import NumberFormat from 'react-number-format';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';

import Form from 'react-bootstrap/Form';
import FormDate from '../forms/FormDate';
import FormEmail from '../forms/FormEmail';
import FormName from '../forms/FormName';

function BehalfInfo(props) {
	const { validated } = props;
	const { INPUT_MAX_LENGTH } = require('../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const referenceData = useSelector(state => state.referenceData);
	const currentApplication = useSelector(state => state.currentApplication);

	const [relationshipOptions, setRelationshipOptions] = useState([]);

	const {
		firstNameBehalf,
		lastNameBehalf,
		birthDateBehalf,
		emailAddressBehalf,
		emailAddressBehalfConfirm,
		phoneNumberBehalf,
		relationshipToApplicant
	} = currentApplication;

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data && referenceData.data.relationshipApplicantDataList) {
			setRelationshipOptions(referenceData.data.relationshipApplicantDataList);
		}
	}, [referenceData]);

	const handleCheckValidity = (event) => {
		if (!event.target.checkValidity()) {
			event.target.closest('.form-group').classList.add('was-validated');
		} else {
			event.target.closest('.form-group').classList.remove('was-validated');
		}
	};

	const handleUpdate = (id, value) => {
		const updatedData = {
			...currentApplication,
			[id]: value
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleRelationshipChange = (id, value) => {
		let fieldVal = value;
		let relationshipOther;

		if (value == 'Other') {
			fieldVal = '';
			relationshipOther = true;
		} else {
			fieldVal = value;
			relationshipOther = false;
		}

		const updatedData = {
			...currentApplication,
			relationshipToApplicant: fieldVal,
			relationshipOther: (relationshipOther) ? relationshipOther : null
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	return (
		<Translator>
			{({ translate }) => (
				<>
					<p><Translate text="As you are filling this application out on behalf of another, please tell us about yourself." /></p>

					<FormName
						id="firstNameBehalf"
						name="firstNameBehalf"
						value={ firstNameBehalf }
						labelText={ translate({ text: 'First name' }) }
						customError={ translate({ text: 'Enter a first name' }) }
						required
						handleOnBlur={(id, value) => { handleUpdate(id, value); }}
					/>

					<FormName
						id="lastNameBehalf"
						name="lastNameBehalf"
						value={ lastNameBehalf }
						labelText={ translate({ text: 'Last name' }) }
						customError={ translate({ text: 'Enter a last name' }) }
						required
						handleOnBlur={(id, value) => { handleUpdate(id, value); }}
					/>

					<FormDate
						id="birthDateBehalf"
						name="birthDateBehalf"
						label={ translate({ text: 'Date of birth' }) }
						value={ birthDateBehalf }
						required={ true }
						validated={ validated }
						customError={ translate({ text: 'Enter a date of birth' }) }
						handleOnChange={(id, value) => handleUpdate(id, value) }
					/>

					<FormEmail
						id="emailAddressBehalf"
						name="emailAddressBehalf"
						value={ emailAddressBehalf }
						confirmValue={ emailAddressBehalfConfirm }
						required
						handleOnChange={(id, value) => { handleUpdate(id, value); }}
					/>

					<Form.Group controlId="phoneNumberBehalf">
						<Form.Label>
							<Translate text="Phone number" /><span className="required">*</span>
						</Form.Label>
						<NumberFormat
							name="phoneNumberBehalf"
							value={ phoneNumberBehalf }
							customInput={ Form.Control }
							type="tel"
							maxLength={ INPUT_MAX_LENGTH }
							format="###-###-####"
							placeholder="###-###-####"
							mask="_"
							required
							onBlur={(e) => { handleUpdate(e.target.name, e.target.value); handleCheckValidity(e); }}
						/>
						<Form.Control.Feedback type="invalid" className="error-phone-number">
							<Translate text="Phone number is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols." />
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="relationshipToApplicant">
						<Form.Label>
							<Translate text="What is your relationship to the applicant?" /><span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="relationshipToApplicant"
							value={ (relationshipToApplicant && !currentApplication.relationshipOther) ? relationshipToApplicant : (currentApplication && currentApplication.relationshipOther) ? 'Other' : '' }
							required
							disabled={ !relationshipOptions || !relationshipOptions.length }
							onChange={(e) => { handleRelationshipChange(e.target.name, e.target.value); }}
							onBlur={(e) => { handleCheckValidity(e); }}
						>
							<option value="">
								{ translate({ text: '- Select -' }) }
							</option>

							{ (Array.isArray(relationshipOptions)) &&
								relationshipOptions.map((item, index) => {
									return (
										<option value={ item.description } key={ index }>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid" className="error-relationship">
							<Translate text="Select your relationship to applicant." />
						</Form.Control.Feedback>
					</Form.Group>

					{ currentApplication && currentApplication.relationshipOther &&
						<Form.Group controlId="relationshipToApplicantSpecify">
							<Form.Label>
								<Translate text="Specify relationship" /><span className="required">*</span>
							</Form.Label>
							<Form.Control
								type="text"
								name="relationshipToApplicant"
								defaultValue={ relationshipToApplicant }
								maxLength={ INPUT_MAX_LENGTH }
								required
								onBlur={(e) => { handleUpdate(e.target.name, e.target.value); handleCheckValidity(e); }}
							/>
							<Form.Control.Feedback type="invalid" className="error-relationship-specify">
								<Translate text="Specify your relationship to the applicant." />
							</Form.Control.Feedback>
						</Form.Group>
					}
				</>
			)}
		</Translator>
	);
}

export default BehalfInfo;
