/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Translate } from 'react-translated';
import { useSelector } from 'react-redux';

import { dataLayerPageView } from '../../helpers/miscHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Landing() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	let location = useLocation();
	const currentPath = location.pathname;

	// State data.
	const language = useSelector((state) => state.language);
	const authenticate = useSelector((state) => state.authenticate);
	const data = useSelector((state) => state.referenceData);
	const [showError, setShowError] = useState(false);
	useEffect(() => {
		const pageTitle = PAGE_TITLE_BASE + ' - Get Started';

		document.title = pageTitle;

		dataLayerPageView(currentPath, pageTitle, language.code);
	}, [language]);

	useEffect(() => {
		if (authenticate && authenticate.error) {
			setShowError(true);
		} else {
			setShowError(false);
		}
	}, [authenticate]);

	return (
		<>
			<Header />

			<Container
				as='main'
				fluid={true}
				className='main'
				id='main'
				tabIndex='-1'
			>
				{showError && (
					<Alert variant='danger'>
						<span className='alert-icon'>
							<FontAwesomeIcon icon={faExclamationCircle} />
						</span>
						<span className='alert-message'>
							<Translate text='Error: Service could not be loaded. Please try again later.' />
						</span>
					</Alert>
				)}

				<Row>
					<Col md={6}>
						<h1>
							<Translate text='Out-of-Province COVID-19 Vaccine Entry Service' />
						</h1>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<h3>
							<Translate text='Nova Scotians can now add COVID-19 vaccines that they received outside the province or through a workplace vaccination program to their Nova Scotia proof of vaccination.' />
						</h3>

						<p>
							<Translate text='The opportunity is available to:' />
						</p>

						<ul>
							<li>
								<Translate text='permanent residents of Nova Scotia who received one or both doses in another province, territory or country' />
							</li>

							<li>
								<Translate text='permanent residents who received one or both doses in a workplace vaccination program, such as members of the Canadian Armed Forces' />
							</li>

							<li>
								<Translate text='people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card' />
							</li>
						</ul>

						<p>
							<Translate
								text='Within the province, you can use your Nova Scotia COVID-19 Proof of Vaccination to prove your vaccination status as part of the <Link>.'
								renderMap={{
									renderLink: () => (
										<a
											href='https://novascotia.ca/coronavirus/proof-of-full-vaccination-policy/'
											target='_blank'
											rel='noopener noreferrer'
										>
											<Translate text='Proof of Full Vaccination Protocol' />
										</a>
									),
								}}
							/>
						</p>

						<h3>
							<Translate text='What you need to know' />
						</h3>

						<p>
							<Translate text="You can complete your own application if you're 16 or older. A parent or guardian needs to complete the application for someone 15 or younger." />
						</p>

						<p>
							<Translate text='You need to provide proof that you received the COVID-19 vaccine, including the date, location, brand and lot number of COVID-19 vaccine you received. Documentation must be issued by the government or the organization that administered the vaccine and show the name of the issuing government or the name and civic address of the organization that administered the vaccine.' />
						</p>

						<p>
							<Translate text='Before you start, make sure you have:' />
						</p>

						<ul>
							<li>
								<Translate text='official proof of vaccination from the province, territory or country where you received 1 or more doses of COVID-19 vaccine' />
							</li>

							<li>
								<Translate text='Canadian Health Card number (if you have one)' />
							</li>

							<li>
								<Translate text='government-issued identification' />
							</li>
						</ul>

						<p>
							<Translate text='It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.' />
						</p>
					</Col>

					<Col md={6}>
						<div className='box'>
							<h3>
								<Translate text='Accepted Proof of Vaccination Documents' />
							</h3>
							<ul>
								<li>
									<Translate text='A screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt. An official proof of the following WHO authorized COVID-19 vaccines:' />
									<ul>
										{data.data &&
											data.data.vaccineNameDataList.map((vaccine, index) => (
												<li key={index}>
													<Translate
														text={vaccine.description}
														renderMap={{
															renderTM: () => <sup>&trade;</sup>, // eslint-disable-line react/display-name
														}}
													/>
												</li>
											))}
									</ul>
								</li>
								<li>
									<Translate text='A photo of an official government ID so we can confirm your details' />
									<ul>
										<li>
											<Translate text='Health Card' />
										</li>
										<li>
											<Translate text='Government Issued ID' />
										</li>
										<li>
											<Translate text="Driver's licence" />
										</li>
										<li>
											<Translate text='Birth Certificate' />
										</li>
										<li>
											<Translate text='Student ID Card' />
										</li>
										<li>
											<Translate text='Secure Certificate of Indian Status' />
										</li>
										<li>
											<Translate text="Veteran's Service Card" />
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Button
							href='/update'
							className='btn-mobile-full'
							id='btn-get-started'
						>
							<Translate text='Update your proof of vaccination' />
						</Button>
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
}

export default Landing;
