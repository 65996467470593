import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Translate } from 'react-translated';

import { scrollUp, dataLayerPageView } from '../../../helpers/miscHelpers';
import { setHasChanges } from '../../../store/actions/globalMessagesActions';
import { applicationActions } from '../../../store/actions/applicationActions';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Intro from './Intro';
import One from './One';
import Two from './Two';
import Three from './Three';
import Review from './Review';

function Steps() {
	const { PAGE_TITLE_BASE } = require('../../../config/constants');
	const dispatch = useDispatch();
	const history = useHistory();
	let location = useLocation();
	const currentPath = location.pathname;

	// State data.
	const language = useSelector(state => state.language);
	const currentApplication = useSelector(state => state.currentApplication);
	const postApplication = useSelector(state => state.postApplication);
	const applicationReview = useSelector(state => state.applicationReview);

	const [step, setStep] = useState(0);
	const [validated, setValidated] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const formRef = useRef(null);

	const isLoading = postApplication.request;
	const loadingClass = (isLoading) ? 'is-loading' : '';

	// Listening to submission post.
	useEffect(() => {
		if (postApplication.success) {
			history.replace({
				pathname: '/success'
			});
			scrollUp();
			dispatch(setHasChanges(false));
		} else if (postApplication.error) {
			setValidated(false);
		}
	}, [postApplication]);

	// Updating page title based on step.
	useEffect(() => {
		updatePageTitle(step);
	}, []);

	// Listening to current application data updates
	useEffect(() => {
		if (validated) {
			setHasErrors(!formRef.current.checkValidity());
		}
	}, [currentApplication]);

	const updatePageTitle = (step) => {
		let newTitle;
		let eventPath = currentPath;

		if (!step || step == 0) {
			newTitle = PAGE_TITLE_BASE + ' - Update Proof of Vaccination - Intro';
		} else if (step == 1) {
			newTitle = PAGE_TITLE_BASE + ' - Update Proof of Vaccination - Step 1 of 3';
			eventPath = eventPath + '/condition';
		} else if (step == 2) {
			newTitle = PAGE_TITLE_BASE + ' - Update Proof of Vaccination - Step 2 of 3';
			eventPath = eventPath + '/applicantInfo';
		} else if (step == 3) {
			newTitle = PAGE_TITLE_BASE + ' - Update Proof of Vaccination - Step 3 of 3';
			eventPath = eventPath + '/vaccinationInfo';
		} else if (step == 4) {
			newTitle = PAGE_TITLE_BASE + ' - Update Proof of Vaccination - Review';
			eventPath = eventPath + '/review';
		}

		document.title = newTitle;

		dataLayerPageView(
			eventPath,
			newTitle,
			language.code
		);
	};

	const handleBack = (num) => {
		setValidated(false);

		if (num || num == 0) {
			setStep(num);
			updatePageTitle(num);
			scrollUp();
		} else if (!num) {
			history.goBack();
			scrollUp();
		}
	};

	const handleRedirect = () => {
		setTimeout(() => {
			window.location.replace('/do-not-qualify');
		}, 150);
	};

	const handleIntroComplete = () => {
		if (applicationReview) {
			setStep(4);
			updatePageTitle(4);
		} else {
			setStep(1);
			updatePageTitle(1);
		}

		scrollUp();
	};

	const handleSelectPersona = async (value) => {
		if (['outside-two-doses', 'visiting', 'none'].includes(value)) {
			await dispatch(setHasChanges(false));
			handleRedirect();
		} else {
			setStep(2);
			updatePageTitle(2);
		}

		scrollUp();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		setValidated(true);

		if (form.checkValidity() === false) {
			setHasErrors(true);
			event.preventDefault();
			event.stopPropagation();
		} else {
			setValidated(false);
			setHasErrors(false);

			if (step == 4) {
				dispatch(applicationActions.postApplication(JSON.stringify(currentApplication)));
			} else if (applicationReview) {
				setStep(4);
				updatePageTitle(4);
				scrollUp();
			} else if (step <= 3) {
				setStep(step + 1);
				updatePageTitle(step + 1);
				scrollUp();
			}
		}
	};

	return (
		<div className={`loading-container ${loadingClass}`}>
			{ (!step || step == 0) &&
				<Intro handleProceed={ handleIntroComplete } />
			}

			{ (step > 0) &&
				<Form
					ref={ formRef }
					noValidate
					validated={ validated }
					onSubmit={ handleSubmit }
				>
					<Button
						id="btn-steps-back"
						variant="link"
						className="mb-2"
						onClick={() => handleBack(step - 1) }
					>
						<Translate text="Back" />
					</Button>

					{ (step == 1) &&
						<One handleProceed={ handleSelectPersona } />
					}

					{ (step == 2) &&
						<Two
							validated={ validated }
							hasErrors={ hasErrors }
						/>
					}

					{ (step == 3) &&
						<Three
							validated={ validated }
							hasErrors={ hasErrors }
						/>
					}

					{ (step == 4) &&
						<Review
							validated={ validated }
							hasErrors={ hasErrors }
							handleEdit={ handleBack }
						/>
					}
				</Form>
			}

			{ isLoading &&
				<Spinner animation="border" role="status" className="loader">
					<span className="sr-only"><Translate text="Loading" /></span>
				</Spinner>
			}
		</div>
	);
}

export default Steps;
