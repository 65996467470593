import React, { useState, useRef } from 'react';
import { Translate } from 'react-translated';
import { validate } from 'validate.js';

import Form from 'react-bootstrap/Form';

export default function FormEmail(props) {
	const [validEmail, setValidEmail] = useState(true);
	const defaultError = 'Enter an email address';
	const [errorMessage, setErrorMessage] = useState(defaultError);
	const [confirmEmailEdited, setConfirmEmailEdited] = useState(false);
	const [confirmEmail, setConfirmEmail] = useState(false);
	const confirmEmailRef = useRef(null);

	const {
		id,
		name,
		value,
		confirmValue,
		required,
		readOnly,
		size,
		disabled
	} = props;

	const [emailValue, setEmailValue] = useState(value);
	const [confirmEmailValue, setConfirmEmailValue] = useState(confirmValue);

	const validConstraints = {
		email: {
			email: true
		}
	};

	const validateEmail = (value) => {
		if (value !== '') {
			const result = validate({email: value}, validConstraints);

			if (result) {
				setValidEmail(false);
				setErrorMessage('Enter an email address in the correct format, like name@example.com');

				if (props.handleValidEmail) {
					props.handleValidEmail(false);
				}
			} else {
				setValidEmail(true);
				setErrorMessage('');

				if (props.handleValidEmail) {
					props.handleValidEmail(true);
				}
			}
		} else if (required && value == '') {
			setValidEmail(false);
			setErrorMessage(defaultError);

			if (props.handleValidEmail) {
				props.handleValidEmail(false);
			}
		} else {
			setValidEmail(true);
			setErrorMessage('');

			if (props.handleValidEmail) {
				props.handleValidEmail(true);
			}
		}
	};

	const handleOnChange = (id, value) => {
		validateEmail(value);

		if (props.handleOnChange) {
			setEmailValue(value);
			props.handleOnChange(id, value);
		}
	};

	const handleOnEmailBlur = (event) => {
		const { value } = event.target;

		validateEmail(value);

		if (props.handleOnBlur) {
			props.handleOnBlur(event);
		}

		// Compare confirm email value with email value.
		if (value && value == confirmEmailRef.current.value) {
			setConfirmEmail(true);
			confirmEmailRef.current.setCustomValidity('');
		} else {
			setConfirmEmail(false);
			confirmEmailRef.current.setCustomValidity('Email address does not match');
		}
	};

	const handleOnConfirmChange = (event) => {
		const value = event.target.value;

		setConfirmEmailEdited(true);

		if (props.handleOnChange) {
			setConfirmEmailValue(value);
			props.handleOnChange(id + 'Confirm', value);
		}

		// Compare confirm email value with email value.
		if (value && value == emailValue) {
			setConfirmEmail(true);
			confirmEmailRef.current.setCustomValidity('');
		} else {
			setConfirmEmail(false);
			confirmEmailRef.current.setCustomValidity('Email address does not match');
		}
	};

	return (
		<>
			<Form.Group controlId={id}>
				<Form.Label>
					<Translate text="Email address" />

					{ required &&
						<span className="required">*</span>
					}
				</Form.Label>

				<Form.Control
					type="email"
					name={ name }
					value={ (emailValue) ? emailValue : '' }
					className={ (size === 'sm') ? 'form-control--small' : null }
					maxLength="100"
					required={ (required) ? true : null }
					isInvalid={ !validEmail }
					readOnly={ (readOnly) ? true : null }
					disabled={ disabled }
					isValid={ (required) ? value && validEmail : validEmail }
					onChange={(e) => { handleOnChange(e.target.id, e.target.value); }}
					onBlur={ handleOnEmailBlur }
				/>
				<Form.Control.Feedback type="invalid" className="error-email-address">
					<Translate text={ errorMessage } />
				</Form.Control.Feedback>
			</Form.Group>

			<Form.Group controlId={id + 'Confirm'}>
				<Form.Label>
					<Translate text="Confirm Email address" />

					{ required &&
						<span className="required">*</span>
					}
				</Form.Label>

				<Form.Control
					ref={ confirmEmailRef }
					type="email"
					name={ name + 'Confirm' }
					value={ (confirmEmailValue) ? confirmEmailValue : '' }
					className={ (size === 'sm') ? 'form-control--small' : null }
					maxLength="100"
					required={ (required) ? true : null }
					readOnly={ (readOnly) ? true : null }
					onPaste={(e)=>{
						e.preventDefault();
						return false;
					}}
					onCopy={(e)=>{
						e.preventDefault();
						return false;
					}}
					autoComplete="off"
					disabled={ disabled }
					isInvalid={ confirmEmailEdited && !confirmEmail }
					isValid={ confirmEmail }
					onChange={ handleOnConfirmChange }
				/>
				<Form.Control.Feedback type="invalid" className="error-confirm-email-address">
					<Translate text="Email address does not match" />
				</Form.Control.Feedback>
			</Form.Group>
		</>
	);
}
