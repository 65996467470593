import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Steps from './steps';

function Update() {
	const history = useHistory();

	useEffect(() => {
		history.replace();
	}, []);

	return (
		<Steps />
	);
}

export default Update;
