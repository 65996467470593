import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import * as globalMessagesActions from '../../store/actions/globalMessagesActions';
import { fileService } from '../../services/fileService';
import { base64toBlob } from '../../helpers/fileHelpers';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

function PrintPDF(props) {
	const { DOC_MIME_TYPE_PDF } = require('../../config/constants');
	const { authDocumentKey } = props;
	const dispatch = useDispatch();

	if (!authDocumentKey) {
		return null;
	}

	const [isDownloading, setIsDownloading] = useState(false);

	const handleFileDownload = async (event, authDocumentKey) => {
		event.preventDefault();

		if (!isDownloading) {
			setIsDownloading(true);

			await fileService.get(authDocumentKey)
				.then(
					response => {
						if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
							if (window.navigator.msSaveBlob) {
								const blob = base64toBlob(response.file);
								window.navigator.msSaveOrOpenBlob(blob, response.filename);
							} else {
								setIsDownloading(false);
								const blob = base64toBlob(response.file, DOC_MIME_TYPE_PDF);
								const tabWindow = window.open('', '_blank');

								if (tabWindow) {
									tabWindow.document.getElementsByTagName('body')[0].innerHTML = '';
									const fileData = window.URL.createObjectURL(blob);
									const htmlPop = `
										<html>
											<head>
												<title>${response.filename}</title>
											</head>
											<body height="100%" width="100%" style="margin: 0;">
												<embed width="100%" height="100%" type="application/pdf" src="${fileData}"></embed>
											</body>
										</html>
									`;

									tabWindow.document.write(htmlPop);
									tabWindow.document.close();
								}
							}
						} else {
							dispatch(globalMessagesActions.error('Error: File could not be downloaded.'));
						}
					}
				);

			setIsDownloading(false);
		}
	};

	return (
		<Button
			id="btn-success-print"
			className="d-print-none print-pdf"
			onClick={(e) => handleFileDownload(e, authDocumentKey)}
		>
			{ isDownloading &&
				<Spinner animation="border" role="status" className="loader" />
			}

			{ !isDownloading &&
				<FontAwesomeIcon icon={ faPrint } />
			}

			<Translate text="Print a copy" />
		</Button>
	);
}

export default PrintPDF;
