import React from 'react';
import { Translate } from 'react-translated';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import MapImage from '../../misc/MapImage';

function One(props) {
	const options = [
		{
			text: 'Permanent resident of Nova Scotia and received one or more doses in another province or country',
			value: 'resident-province-country'
		},
		{
			text: 'Permanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)',
			value: 'resident-vaccination-program'
		},
		{
			text: 'Recently moved to Nova Scotia (permanently)',
			value: 'moved-here'
		},
		{
			text: 'From out-of-province and received one dose in Nova Scotia',
			value: 'outside-one-dose'
		},
		{
			text: 'From out-of-province and need to schedule a booster dose while in Nova Scotia',
			value: 'booster'
		},
		{
			text: 'From out-of-province and received two doses in my home province or country',
			value: 'outside-two-doses'
		},
		{
			text: 'Visiting Nova Scotia',
			value: 'visiting'
		},
		{
			text: 'None of the above',
			value: 'none'
		}
	];

	const handleSelect = (value) => {
		if (props.handleProceed) {
			props.handleProceed(value);
		}
	};

	return (
		<div id="step-1">
			<Row className="align-items-center">
				<Col sm={8}>
					<h1><Translate text="Which of the following applies to the applicant?" /></h1>
				</Col>
				<Col sm={4} className="text-sm-right">
					<h2> <Translate text="Step 1 of 3" /></h2>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<ListGroup variant="button-list">
						{ options.map((item, index) => {
							return (
								<ListGroup.Item key={ index }>
									<span className="list-group-button-list__text">
										<Translate text={ item.text } />
									</span>
									<Button
										id={`btn-step1-${item.value}`}
										size="sm"
										onClick={() => handleSelect(item.value) }
									>
										<Translate text="Select" />
									</Button>
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				</Col>
				<Col md={6}>
					<MapImage />
				</Col>
			</Row>
		</div>
	);
}

export default One;
