import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-translated';

import Spinner from 'react-bootstrap/Spinner';

function ContentLoading(props) {
	const { loading } = props;

	// State data.
	const authenticate = useSelector(state => state.authenticate);

	const isLoading = authenticate.request || loading;
	const loadingClass = (isLoading) ? 'is-loading' : '';

	return (
		<div className={`content-loading loading-container ${loadingClass}`}>
			{ props.children }

			{ isLoading &&
				<Spinner animation="border" role="status" className="loader">
					<span className="sr-only"><Translate text="Loading" /></span>
				</Spinner>
			}
		</div>
	);
}

export default ContentLoading;
