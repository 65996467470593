import React, { useEffect } from 'react';
import { Translate } from 'react-translated';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function NoRoute() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');

	useEffect(() => {
		document.title = PAGE_TITLE_BASE + ' - Not Found';
	}, []);

	return (
		<>
			<Header />

			<Container as="main" fluid={ true } className="main" id="main" tabIndex="-1">
				<Row>
					<Col>
						<h1><Translate text="We couldn't find that page" /></h1>
						<Button href="/">
							<Translate text="Go to start now" />
						</Button>
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
}

export default NoRoute;
