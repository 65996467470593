import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';

import Button from 'react-bootstrap/Button';

function Cancel() {
	const dispatch = useDispatch();

	const handleCancel = (event) => {
		event.preventDefault();
		dispatch(applicationActions.currentApplicationReset());
		dispatch(setHasChanges(false));
		window.location.replace('/');
	};

	return (
		<Button
			id="btn-cancel"
			variant="link"
			onClick={ handleCancel }
		>
			<Translate text="Cancel" />
		</Button>
	);
}

export default Cancel;
