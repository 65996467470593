import React from 'react';
import { Translate } from 'react-translated';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Cancel from '../../misc/Cancel';
import ApplicantInfo from '../ApplicantInfo';
import HealthCardDetails from '../HealthCardDetails';
import UploadGovID from '../UploadGovID';

function Two(props) {
	const {
		validated,
		hasErrors
	} = props;

	return (
		<div id="step-2">
			<Row className="align-items-center">
				<Col sm={8}>
					<h1><Translate text="Applicant’s Information" /></h1>
				</Col>
				<Col sm={4} className="text-sm-right">
					<h2> <Translate text="Step 2 of 3" /></h2>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<ApplicantInfo validated={ validated } />

					<hr />

					<HealthCardDetails validated={ validated } />

					<hr />
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<UploadGovID validated={ validated } />
				</Col>
				<Col md={6}>
					<div className="box">
						<h3><Translate text="Accepted official government ID:" /></h3>
						<ul>
							<li><Translate text="Health Card" /></li>
							<li><Translate text="Government Issued ID" /></li>
							<li><Translate text="Driver's licence" /></li>
							<li><Translate text="Birth Certificate" /></li>
							<li><Translate text="Student ID Card" /></li>
							<li><Translate text="Secure Certificate of Indian Status" /></li>
							<li><Translate text="Veteran's Service Card" /></li>
						</ul>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<ButtonToolbar className="pt-2">
						<Button type="submit" id="btn-step2-continue">
							<Translate text="Continue" />
						</Button>

						<Cancel />
					</ButtonToolbar>

					{ (validated && hasErrors) &&
						<div className="invalid-feedback show error-summary-message" id="error-summary-step2">
							<p><Translate text="One or more fields are missing or incorrect" /></p>
						</div>
					}
				</Col>
			</Row>
		</div>
	);
}

export default Two;
