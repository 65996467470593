export function getContentType(contentType) {
	const {
		BLANK_VALUE
	} = require('../config/constants');

	const types = {
		'application/pdf': 'PDF',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
		'image/png': 'PNG',
		'image/jpeg': 'JPEG',
		'text/plain': 'TEXT',
		'application/msword': 'DOC',
		'application/vnd.ms-excel': 'XLS',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
	};

	return types[contentType] || BLANK_VALUE;
}

export function base64toBlob(base64Data, contentType) {
	let blobContentType = contentType || '';
	var sliceSize = 1024;
	var byteCharacters = atob(base64Data);
	var bytesLength = byteCharacters.length;
	var slicesCount = Math.ceil(bytesLength / sliceSize);
	var byteArrays = new Array(slicesCount);

	for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		var begin = sliceIndex * sliceSize;
		var end = Math.min(begin + sliceSize, bytesLength);

		var bytes = new Array(end - begin);
		for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}

	return new Blob(byteArrays, { type: blobContentType });
}

export function fileToBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export function bytesToSize(bytes) {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	if (bytes == 0) {
		return '0 Byte';
	}

	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
