import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-translated';
import unorm from 'unorm';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';
import { fileToBase64, bytesToSize } from '../../helpers/fileHelpers';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function UploadVaccineConfirm(props) {
	const {
		item,
		index,
		validated
	} = props;
	const {
		DOC_TYPE_VACCINE_CONFIRM,
		DOC_CONTENT_TYPE_PDF,
		DOC_CONTENT_TYPE_JPEG,
		DOC_CONTENT_TYPE_PNG,
		DOC_MIME_TYPE_PDF,
		DOC_MIME_TYPE_JPEG,
		DOC_MIME_TYPE_PNG,
		DOC_MIME_TYPES,
		DOC_MAX_FILESIZE,
		DOC_MAX_FILENAME
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const buttonTextDefault = 'Upload File';
	const [uploads, setUploads] = useState([]);
	const [typeError, setTypeError] = useState('');
	const [filesizeError, setFilesizeError] = useState('');
	const [filenameError, setFilenameError] = useState('');
	const [typeFiles, setTypeFiles] = useState([]);
	const [filesizeFiles, setFilesizeFiles] = useState([]);
	const [filenameFiles, setFilenameFiles] = useState([]);
	const filesizeString = bytesToSize(DOC_MAX_FILESIZE);
	const fileElement = useRef(null);
	const mimeTypes = DOC_MIME_TYPES;

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const currentApplication = useSelector(state => state.currentApplication);

	useEffect(() => {
		if (currentApplication && currentApplication.vaccinationDocumentList) {
			let currentUploads = [...currentApplication.vaccinationDocumentList];
			currentUploads = currentUploads.filter(item => item && item.documentType == DOC_TYPE_VACCINE_CONFIRM);

			if (currentUploads.length) {
				setUploads(currentUploads);
			} else {
				setUploads([{ documentType: DOC_TYPE_VACCINE_CONFIRM }]);
			}
		}
	}, [currentApplication]);

	const processFiles = async (fileList, index) => {
		let files;
		setTypeError('');
		setFilesizeError('');
		setFilenameError('');
		fileElement.current.setCustomValidity('');
		setTypeFiles([]);
		setFilesizeFiles([]);
		setFilenameFiles([]);

		files = [...fileList];

		const promises = files.map((file) => {
			let contentType;
			let newUpload;

			return new Promise((res) => {
				if (Array.isArray(mimeTypes) && !mimeTypes.includes(file.type)) {
					setTypeError('Incorrect file type.');
					fileElement.current.setCustomValidity('Incorrect file type.');
					setTypeFiles(typeFiles => [...typeFiles, file.name]);
					return false;
				}

				if (file.size > DOC_MAX_FILESIZE) {
					setFilesizeError(`Some files exceed the ${filesizeString} limit.`);
					fileElement.current.setCustomValidity(`Some files exceed the ${filesizeString} limit.`);
					setFilesizeFiles(filesizeFiles => [...filesizeFiles, file.name]);
					return false;
				}

				if (file.name.length > DOC_MAX_FILENAME) {
					setFilenameError(`Some file names exceed the ${DOC_MAX_FILENAME} character limit.`);
					fileElement.current.setCustomValidity(`Some file names exceed the ${DOC_MAX_FILENAME} character limit.`);
					setFilenameFiles(filenameFiles => [...filenameFiles, file.name]);
					return false;
				}

				switch (file.type) {
					case DOC_MIME_TYPE_PDF:
						contentType = DOC_CONTENT_TYPE_PDF;
						break;
					case DOC_MIME_TYPE_JPEG:
						contentType = DOC_CONTENT_TYPE_JPEG;
						break;
					case DOC_MIME_TYPE_PNG:
						contentType = DOC_CONTENT_TYPE_PNG;
						break;
					default:
						break;
				}

				// Convert to Base64 & add to array.
				fileToBase64(file)
					.then((raw) => {
						const data = raw.split(',')[1];

						if (data && file) {
							let filenameExt = file.name.split('.').pop();
							let filename = file.name.split('.').slice(0, -1).join('.');

							if (filename) {
								filename = filename.trim() + '-confirmation-' + index + (Math.floor(100000 + (Math.random() * 900000))) + '.' + filenameExt;
							} else {
								filename = file.name;
							}

							newUpload = {
								changeType: 'SAVE',
								filename: unorm.nfc(filename),
								documentContentType: contentType,
								documentType: DOC_TYPE_VACCINE_CONFIRM,
								data: data
							};

							res(newUpload);
						}
					})
					.catch((error) => {
						console.log(error); // eslint-disable-line
						return false;
					});
			});
		});

		const newUpload = await Promise.all(promises);

		if (typeError == '' && filesizeError == '' && filenameError == '') {
			const newFile = (newUpload && newUpload.length) ? newUpload[0] : {};

			let otherUploads = (Array.isArray(currentApplication.vaccinationDocumentList)) ? [...currentApplication.vaccinationDocumentList] : [];
			otherUploads = otherUploads.filter(item => item && item.documentType !== DOC_TYPE_VACCINE_CONFIRM);

			let items = [...uploads];
			items[index] = newFile;

			const updatedFiles = otherUploads.concat(items);

			const updatedData = {
				...currentApplication,
				vaccinationDocumentList: updatedFiles
			};

			dispatch(applicationActions.currentApplicationUpdate(updatedData));
		}
	};

	const handleUpdate = (event, index) => {
		const fileList = fileElement.current.files;
		processFiles(fileList, index);

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleRemoveFile = (event, index) => {
		fileElement.current.value = '';
		fileElement.current.setCustomValidity('');

		let otherUploads = (Array.isArray(currentApplication.vaccinationDocumentList)) ? [...currentApplication.vaccinationDocumentList] : [];
		otherUploads = otherUploads.filter(item => item && item.documentType !== DOC_TYPE_VACCINE_CONFIRM);
		let items = [...uploads];

		if (index > -1) {
			items.splice(index, 1);
		}

		const updatedFiles = otherUploads.concat(items);

		const updatedData = {
			...currentApplication,
			vaccinationDocumentList: updatedFiles
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));
	};

	return (
		<>
			<Row>
				<Col md={9}>
					<Form.Control
						ref={ fileElement }
						id={`vaccineConfirm-${index}`}
						type="file"
						className="visuallyhidden"
						aria-describedby="upload-vaccine-confirm-help-text"
						required={ !item || !item.data }
						accept={ (Array.isArray(mimeTypes)) ? mimeTypes.toString() : null }
						isInvalid={ !item || !item.data || typeError !== '' || filesizeError !== '' || filenameError !== '' }
						isValid={ item && item.data && typeError == '' && filesizeError == '' && filenameError == '' }
						onChange={(e) => { handleUpdate(e, index); }}
					/>
					<Form.Label htmlFor={`vaccineConfirm-${index}`}>
						<Translate text={ (item && item.filename) ? item.filename : buttonTextDefault } />

						{ (item && item.data) &&
							<FontAwesomeIcon icon={ faCheckCircle } />
						}
					</Form.Label>
				</Col>
				<Col md={3} className="upload-remove">
					{ (uploads && uploads.length > 1) &&
						<Button
							variant="link"
							className="text-danger ml-2"
							onClick={(e) => { handleRemoveFile(e, index); }}
						>
							<Translate text="Remove" />
						</Button>
					}
				</Col>
			</Row>

			<div className={`invalid-feedback error-vaccine-confirm-feedback ${(typeError || filesizeError || filenameError) ? 'show' : ''}`}>
				<div><Translate text={ typeError } /></div>
				{ typeFiles && (typeFiles.length > 0) &&
					<ul>
						{
							typeFiles.map((file, index) => {
								return (
									<li key={ index }>
										{ file }
									</li>
								);
							})
						}
					</ul>
				}

				<div><Translate text={ filesizeError } /></div>
				{ filesizeFiles && (filesizeFiles.length > 0) &&
					<ul>
						{
							filesizeFiles.map((file, index) => {
								return (
									<li key={ index }>
										{ file }
									</li>
								);
							})
						}
					</ul>
				}

				<div><Translate text={ filenameError } /></div>
				{ filenameFiles && (filenameFiles.length > 0) &&
					<ul>
						{
							filenameFiles.map((file, index) => {
								return (
									<li key={ index }>
										{ file }
									</li>
								);
							})
						}
					</ul>
				}
			</div>

			{ validated && (!item || !item.data) &&
				<div className="invalid-feedback show error-vaccine-confirm-feedback">
					<Translate text="File upload is required." />
				</div>
			}
		</>
	);
}

export default UploadVaccineConfirm;
