import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Translate } from 'react-translated';
import { useSelector } from 'react-redux';

import { dataLayerPageView } from '../../helpers/miscHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import MapImage from '../misc/MapImage';

function DoNotQualify() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	let location = useLocation();
	const currentPath = location.pathname;

	// State data.
	const language = useSelector(state => state.language);

	useEffect(() => {
		const pageTitle = PAGE_TITLE_BASE + ' - Do Not Qualify';

		document.title = pageTitle;

		dataLayerPageView(
			currentPath,
			pageTitle,
			language.code
		);
	}, [language]);

	return (
		<>
			<Header />

			<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
				<Row>
					<Col md={6}>
						<Button
							variant="link"
							className="mb-2"
							href="/"
						>
							<Translate text="Back" />
						</Button>
						<h1><Translate text="Sorry, you do not qualify for the Out-of-Province COVID-19 Vaccine Entry Service." /></h1>
						<p><Translate text="Only permanent residents or people who received at least 1 dose of COVID-19 vaccine Nova Scotia, or people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card can get their Nova Scotia COVID-19 Proof of Vaccination." /></p>
						<p><Translate text="If you need to show proof of full vaccination during your stay in Nova Scotia, you need to provide proof of vaccination from where you were vaccinated." /></p>
						<p><Translate text="You must be 16 years of age or older to submit an application. Anyone under the age of 16 will require a guardian to submit the application on their behalf." /></p>
						<p>
							<Translate
								text="Learn more about <Link>"
								renderMap={{
									renderLink: () => <a href="https://novascotia.ca/coronavirus/proof-of-full-vaccination-policy/" target="_blank" rel="noopener noreferrer"><Translate text="Nova Scotia’s Proof of Vaccination Policy" /></a> // eslint-disable-line react/display-name
								}}
							/>
						</p>
					</Col>
					<Col md={6}>
						<MapImage />
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
}

export default DoNotQualify;
