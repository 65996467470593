import React, { useState, useRef } from 'react';
import { Translate } from 'react-translated';

import Form from 'react-bootstrap/Form';

export default function FormName(props) {
	const { INPUT_MAX_LENGTH } = require('../../config/constants');

	const {
		id,
		name,
		value,
		required,
		labelText,
		customError,
		disabled
	} = props;

	const label = (labelText) ? labelText : 'Name';
	const defaultError = (customError) ? customError : `${label} is a required field.`;
	const [errorMessage, setErrorMessage] = useState(defaultError);
	const fieldRef = useRef(null);

	const handleCheckValidity = (event) => {
		if (!event.target.checkValidity()) {
			event.target.closest('.form-group').classList.add('was-validated');
		} else {
			event.target.closest('.form-group').classList.remove('was-validated');
		}
	};

	const handleOnChange = (id, value) => {
		if (value && value.length < 2) {
			setErrorMessage(`${label} can not be less than 2 characters`);
		} else if (fieldRef.current) {
			if (fieldRef.current.validity.patternMismatch) {
				setErrorMessage(`${label} can't contain characters like ! @ # $ % & ?`);
			} else {
				setErrorMessage(defaultError);
			}
		} else {
			setErrorMessage(defaultError);
		}

		if (props.handleOnChange) {
			props.handleOnChange(id, value);
		}
	};

	const handleOnBlur = (id, value) => {
		if (props.handleOnBlur) {
			props.handleOnBlur(id, value);
		}
	};

	return (
		<Form.Group controlId={ id }>
			<Form.Label>
				<Translate text={ label } />

				{ required &&
					<span className="required">*</span>
				}
			</Form.Label>
			<Form.Control
				ref={ fieldRef }
				type="text"
				name={ name }
				defaultValue={ value }
				maxLength={ INPUT_MAX_LENGTH }
				minLength={ 2 }
				pattern="[^()[\]=<>!@#$%&?]+"
				required={ (required) ? true : null }
				disabled={ (disabled) ? true : null }
				onChange={(e) => { handleOnChange(e.target.name, e.target.value); }}
				onBlur={(e) => { handleOnBlur(e.target.name, e.target.value); handleCheckValidity(e); }}
			/>
			<Form.Control.Feedback type="invalid" className="error-name">
				<Translate text={ errorMessage } />
			</Form.Control.Feedback>
		</Form.Group>
	);
}
