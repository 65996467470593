import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Translate } from 'react-translated';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';
import { dataLayerPageView } from '../../helpers/miscHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import PrintPDF from '../update/PrintPDF';
import SavePDF from '../update/SavePDF';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Success() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	const dispatch = useDispatch();
	const history = useHistory();
	let location = useLocation();
	const currentPath = location.pathname;
	const [referenceNumber, setReferenceNumber] = useState(null);
	const [authDocumentKey, setAuthDocumentKey] = useState(null);
	const [showError, setShowError] = useState(false);
	const containerRef = useRef(null);

	// State data.
	const postApplication = useSelector(state => state.postApplication);
	const language = useSelector(state => state.language);

	useEffect(() => {
		dispatch(applicationActions.applicationReviewReset());
	}, []);

	useEffect(() => {
		const pageTitle = PAGE_TITLE_BASE + ' - Success';

		document.title = pageTitle;

		dataLayerPageView(
			currentPath,
			pageTitle,
			language.code
		);
	}, [language]);

	useEffect(() => {
		if (!postApplication || !postApplication.success || !postApplication.data) {
			history.replace({
				pathname: '/'
			});
		}

		if (postApplication && postApplication.success && postApplication.data) {
			if (!postApplication.data.referenceNumber) {
				history.replace({
					pathname: '/'
				});
				setShowError(true);
			} else {
				setReferenceNumber(postApplication.data.referenceNumber);
				setShowError(false);
			}

			if (postApplication.data.authDocumentKey) {
				setAuthDocumentKey(postApplication.data.authDocumentKey);
			}
		}
	}, [postApplication]);

	const handleReset = () => {
		dispatch(applicationActions.postApplicationReset());
		dispatch(applicationActions.currentApplicationReset());
		dispatch(setHasChanges(false));
		window.location.replace('/');
	};

	return (
		<>
			<Header />

			<Container as="main" fluid={true} className="main" id="main" tabIndex="-1" ref={ containerRef }>
				<Row>
					<Col md={8}>
						{ showError &&
							<Alert variant="danger" onClose={() => setShowError(false)} dismissible>
								<span className="alert-icon">
									<FontAwesomeIcon icon={ faExclamationCircle } />
								</span>
								<span className="alert-message">
									<Translate text="Error: Reference number not found. Please contact an admin." />
								</span>
							</Alert>
						}

						<div className="success-msg">
							<h1 className="text-success">
								<FontAwesomeIcon size="lg" icon={ faCheckCircle } />
								<Translate text="Application successfully submitted!" />
							</h1>
							<div className="success-msg__inner">
								{ referenceNumber &&
									<h2>
										<Translate text="Your reference number is:" /> <strong>{ referenceNumber }</strong>
									</h2>
								}

								<p><Translate text="We have emailed you a confirmation of your submission." /></p>

								<p><Translate text="You may also wish to print or save a copy of this page for your records." /></p>

								{ authDocumentKey &&
									<ButtonToolbar className="d-print-none">
										<PrintPDF authDocumentKey={ authDocumentKey } />

										<SavePDF authDocumentKey={ authDocumentKey } />
									</ButtonToolbar>
								}

								<Button
									id="btn-success-all-finished"
									variant="link"
									onClick={ handleReset }
								>
									<Translate text="All finished, back to start" />
								</Button>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col md={7}>
						<h2><Translate text="What happens next?" /></h2>

						<p><Translate text="Your application has been submitted. It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required." /></p>

						<p><Translate text="If your submission is incomplete, our support team will contact you by email or phone." /></p>

						<p><Translate text="When your Nova Scotia COVID-19 Proof of Vaccination is updated, you will receive an email from noreply@canimmunize.ca with instructions on how to access your proof of vaccination." /></p>

						<p><Translate text="Until you receive your updated Nova Scotia COVID-19 Proof of Vaccination, you should continue to use the vaccine record provided by the province, territory or country that administered the vaccine." /></p>

						<ButtonToolbar className="justify-content-between pt-2 d-print-none">
							<Button onClick={ handleReset } id="btn-success-add-another">
								<Translate text="Add Another Person's Vaccination Details" />
							</Button>
						</ButtonToolbar>
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
}

export default Success;
