/* eslint-disable id-length */
export default {
	'Nova Scotia': {
		en: 'Nova Scotia',
		fr: 'Nova Scotia'
	},
	'Out-of-Province COVID-19 Vaccine Entry Service': {
		en: 'Out-of-Province COVID-19 Vaccine Entry Service',
		fr: 'Out-of-Province COVID-19 Vaccine Entry Service'
	},
	'Maximum {number} options selected': {
		en: 'Maximum {number} options selected',
		fr: 'Maximum {number} options selected'
	},
	'Date is required.': {
		en: 'Date is required.',
		fr: 'Date is required.'
	},
	'Enter an email address': {
		en: 'Enter an email address',
		fr: 'Enter an email address'
	},
	'Email address does not match': {
		en: 'Email address does not match',
		fr: 'Email address does not match'
	},
	'Email': {
		en: 'Email',
		fr: 'Email'
	},
	'Enter an email address in the correct format, like name@example.com': {
		en: 'Enter an email address in the correct format, like name@example.com',
		fr: 'Enter an email address in the correct format, like name@example.com'
	},
	'Name': {
		en: 'Name',
		fr: 'Name'
	},
	'First name is a required field.': {
		en: 'First name is a required field.',
		fr: 'First name is a required field.'
	},
	'Last name is a required field.': {
		en: 'Last name is a required field.',
		fr: 'Last name is a required field.'
	},
	'First name can not be less than 2 characters': {
		en: 'First name can not be less than 2 characters',
		fr: 'First name can not be less than 2 characters'
	},
	'Last name can not be less than 2 characters': {
		en: 'Last name can not be less than 2 characters',
		fr: 'Last name can not be less than 2 characters'
	},
	'First name can\'t contain characters like ! @ # $ % & ?': {
		en: 'First name can\'t contain characters like ! @ # $ % & ?',
		fr: 'First name can\'t contain characters like ! @ # $ % & ?'
	},
	'Last name can\'t contain characters like ! @ # $ % & ?': {
		en: 'Last name can\'t contain characters like ! @ # $ % & ?',
		fr: 'Last name can\'t contain characters like ! @ # $ % & ?'
	},
	'Privacy': {
		en: 'Privacy',
		fr: 'Privacy'
	},
	'Terms': {
		en: 'Terms',
		fr: 'Terms'
	},
	'Crown copyright &copy; Government of Nova Scotia': {
		en: 'Crown copyright &copy; Government of Nova Scotia',
		fr: 'Crown copyright &copy; Government of Nova Scotia'
	},
	'BETA': {
		en: 'BETA',
		fr: 'BETA'
	},
	'This is a new service that we\'re still developing. Try the service and <Link>.': {
		en: 'This is a new service that we\'re still developing. Try the service and <Link>.',
		fr: 'This is a new service that we\'re still developing. Try the service and <Link>.'
	},
	'send us your comments and suggestions': {
		en: 'send us your comments and suggestions',
		fr: 'send us your comments and suggestions'
	},
	'Your feedback will help make the service easier to use for everyone.': {
		en: 'Your feedback will help make the service easier to use for everyone.',
		fr: 'Your feedback will help make the service easier to use for everyone.'
	},
	'Cancel': {
		en: 'Cancel',
		fr: 'Cancel'
	},
	'Skip to main content': {
		en: 'Skip to main content',
		fr: 'Skip to main content'
	},
	'Back': {
		en: 'Back',
		fr: 'Back'
	},
	'Sorry, you do not qualify for the Out-of-Province COVID-19 Vaccine Entry Service.': {
		en: 'Sorry, you do not qualify for the Out-of-Province COVID-19 Vaccine Entry Service.',
		fr: 'Sorry, you do not qualify for the Out-of-Province COVID-19 Vaccine Entry Service.'
	},
	'Only permanent residents or people who received at least 1 dose of COVID-19 vaccine Nova Scotia, or people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card can get their Nova Scotia COVID-19 Proof of Vaccination.': {
		en: 'Only permanent residents or people who received at least 1 dose of COVID-19 vaccine Nova Scotia, or people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card can get their Nova Scotia COVID-19 Proof of Vaccination.',
		fr: 'Only permanent residents or people who received at least 1 dose of COVID-19 vaccine Nova Scotia, or people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card can get their Nova Scotia COVID-19 Proof of Vaccination.'
	},
	'If you need to show proof of full vaccination during your stay in Nova Scotia, you need to provide proof of vaccination from where you were vaccinated.': {
		en: 'If you need to show proof of full vaccination during your stay in Nova Scotia, you need to provide proof of vaccination from where you were vaccinated.',
		fr: 'If you need to show proof of full vaccination during your stay in Nova Scotia, you need to provide proof of vaccination from where you were vaccinated.'
	},
	'proof of vaccination for people from other provinces, territories and countries': {
		en: 'proof of vaccination for people from other provinces, territories and countries',
		fr: 'proof of vaccination for people from other provinces, territories and countries'
	},
	'Update your Nova Scotia COVID-19 Proof of Vaccination to include your out-of-province vaccinations.': {
		en: 'Update your Nova Scotia COVID-19 Proof of Vaccination to include your out-of-province vaccinations.',
		fr: 'Update your Nova Scotia COVID-19 Proof of Vaccination to include your out-of-province vaccinations.'
	},
	'You must be 16 years of age or older to submit an application. Anyone under the age of 16 will require a guardian to submit the application on their behalf.': {
		en: 'You must be 16 years of age or older to submit an application. Anyone under the age of 16 will require a guardian to submit the application on their behalf.',
		fr: 'You must be 16 years of age or older to submit an application. Anyone under the age of 16 will require a guardian to submit the application on their behalf.'
	},
	'Learn more about <Link>.': {
		en: 'Learn more about <Link>.',
		fr: 'Learn more about <Link>.'
	},
	'Nova Scotia’s Proof of Vaccination PolicyPermanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)': {
		en: 'Nova Scotia’s Proof of Vaccination PolicyPermanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)',
		fr: 'Nova Scotia’s Proof of Vaccination PolicyPermanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)'
	},
	'If you’re a permanent resident or received at least 1 dose in Nova Scotia, you can update your Nova Scotia COVID-19 Proof of Vaccination to include your out-of-province COVID-19 vaccinations.': {
		en: 'If you’re a permanent resident or received at least 1 dose in Nova Scotia, you can update your Nova Scotia COVID-19 Proof of Vaccination to include your out-of-province COVID-19 vaccinations.',
		fr: 'If you’re a permanent resident or received at least 1 dose in Nova Scotia, you can update your Nova Scotia COVID-19 Proof of Vaccination to include your out-of-province COVID-19 vaccinations.'
	},
	'Within the province, you can use your Nova Scotia COVID-19 Proof of Vaccination to prove your vaccination status as part of the <Link>.': {
		en: 'Within the province, you can use your Nova Scotia COVID-19 Proof of Vaccination to prove your vaccination status as part of the <Link>.',
		fr: 'Within the province, you can use your Nova Scotia COVID-19 Proof of Vaccination to prove your vaccination status as part of the <Link>.'
	},
	'Proof of Full Vaccination Protocol': {
		en: 'Proof of Full Vaccination Protocol',
		fr: 'Proof of Full Vaccination Protocol'
	},
	'What you need to know': {
		en: 'What you need to know',
		fr: 'What you need to know'
	},
	'You can complete your own application if you\'re 16 or older. A parent or guardian needs to complete the application for someone 15 or younger.': {
		en: 'You can complete your own application if you\'re 16 or older. A parent or guardian needs to complete the application for someone 15 or younger.',
		fr: 'You can complete your own application if you\'re 16 or older. A parent or guardian needs to complete the application for someone 15 or younger.'
	},
	'You need to provide proof that you received the COVID-19 vaccine, including the date, location, brand and lot number of COVID-19 vaccine you received. Documentation must be issued by the government or the organization that administered the vaccine and show the name of the issuing government or the name and civic address of the organization that administered the vaccine.': {
		en: 'You need to provide proof that you received the COVID-19 vaccine, including the date, location, brand and lot number of COVID-19 vaccine you received. Documentation must be issued by the government or the organization that administered the vaccine and show the name of the issuing government or the name and civic address of the organization that administered the vaccine.',
		fr: 'You need to provide proof that you received the COVID-19 vaccine, including the date, location, brand and lot number of COVID-19 vaccine you received. Documentation must be issued by the government or the organization that administered the vaccine and show the name of the issuing government or the name and civic address of the organization that administered the vaccine.'
	},
	'Before you start, make sure you have:': {
		en: 'Before you start, make sure you have:',
		fr: 'Before you start, make sure you have:'
	},
	'official proof of vaccination from the province, territory or country where you received 1 or more doses of COVID-19 vaccine': {
		en: 'official proof of vaccination from the province, territory or country where you received 1 or more doses of COVID-19 vaccine',
		fr: 'official proof of vaccination from the province, territory or country where you received 1 or more doses of COVID-19 vaccine'
	},
	'Canadian Health Card number (if you have one)': {
		en: 'Canadian Health Card number (if you have one)',
		fr: 'Canadian Health Card number (if you have one)'
	},
	'government-issued identification': {
		en: 'government-issued identification',
		fr: 'government-issued identification'
	},
	'It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.': {
		en: 'It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.',
		fr: 'It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.'
	},
	'Accepted Proof of Vaccination Documents': {
		en: 'Accepted Proof of Vaccination Documents',
		fr: 'Accepted Proof of Vaccination Documents'
	},
	'Accepted official government ID:': {
		en: 'Accepted official government ID:',
		fr: 'Accepted official government ID:'
	},
	'A screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt': {
		en: 'A screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt',
		fr: 'A screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt'
	},
	'A photo of an official government ID so we can confirm your details': {
		en: 'A photo of an official government ID so we can confirm your details',
		fr: 'A photo of an official government ID so we can confirm your details'
	},
	'Government Issued ID': {
		en: 'Government Issued ID',
		fr: 'Government Issued ID'
	},
	'Driver\'s licence': {
		en: 'Driver\'s licence',
		fr: 'Driver\'s licence'
	},
	'Passport': {
		en: 'Passport',
		fr: 'Passport'
	},
	'Health Card': {
		en: 'Health Card',
		fr: 'Health Card'
	},
	'Student ID Card': {
		en: 'Student ID Card',
		fr: 'Student ID Card'
	},
	'Veteran\'s Service Card': {
		en: 'Veteran\'s Service Card',
		fr: 'Veteran\'s Service Card'
	},
	'Secure Certificate of Indian Status': {
		en: 'Secure Certificate of Indian Status',
		fr: 'Secure Certificate of Indian Status'
	},
	'Update your proof of vaccination': {
		en: 'Update your proof of vaccination',
		fr: 'Update your proof of vaccination'
	},
	'We couldn\'t find that page': {
		en: 'We couldn\'t find that page',
		fr: 'We couldn\'t find that page'
	},
	'Go to start now': {
		en: 'Go to start now',
		fr: 'Go to start now'
	},
	'Form successfully submitted!': {
		en: 'Form successfully submitted!',
		fr: 'Form successfully submitted!'
	},
	'Your reference number is:': {
		en: 'Your reference number is:',
		fr: 'Your reference number is:'
	},
	'You may also wish to print or save a copy of this page for your records.': {
		en: 'You may also wish to print or save a copy of this page for your records.',
		fr: 'You may also wish to print or save a copy of this page for your records.'
	},
	'All finished, back to start': {
		en: 'All finished, back to start',
		fr: 'All finished, back to start'
	},
	'What happens next?': {
		en: 'What happens next?',
		fr: 'What happens next?'
	},
	'Your form has been submitted. It should take 2 to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed.': {
		en: 'Your form has been submitted. It should take 2 to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed.',
		fr: 'Your form has been submitted. It should take 2 to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed.'
	},
	'If your submission is incomplete, our support team will contact you by email or phone.': {
		en: 'If your submission is incomplete, our support team will contact you by email or phone.',
		fr: 'If your submission is incomplete, our support team will contact you by email or phone.'
	},
	'When your Nova Scotia COVID-19 Proof of Vaccination is updated, you will receive an email from noreply@canimmunize.ca with instructions on how to access your proof of vaccination.': {
		en: 'When your Nova Scotia COVID-19 Proof of Vaccination is updated, you will receive an email from noreply@canimmunize.ca with instructions on how to access your proof of vaccination.',
		fr: 'When your Nova Scotia COVID-19 Proof of Vaccination is updated, you will receive an email from noreply@canimmunize.ca with instructions on how to access your proof of vaccination.'
	},
	'Until you receive your updated Nova Scotia COVID-19 Proof of Vaccination, you should continue to use the vaccine record provided by the province, territory or country that administered the vaccine.': {
		en: 'Until you receive your updated Nova Scotia COVID-19 Proof of Vaccination, you should continue to use the vaccine record provided by the province, territory or country that administered the vaccine.',
		fr: 'Until you receive your updated Nova Scotia COVID-19 Proof of Vaccination, you should continue to use the vaccine record provided by the province, territory or country that administered the vaccine.'
	},
	'Add Another Person\'s Vaccination Details': {
		en: 'Add Another Person\'s Vaccination Details',
		fr: 'Add Another Person\'s Vaccination Details'
	},
	'First name': {
		en: 'First name',
		fr: 'First name'
	},
	'Enter a first name': {
		en: 'Enter a first name',
		fr: 'Enter a first name'
	},
	'Last name': {
		en: 'Last name',
		fr: 'Last name'
	},
	'Enter a last name': {
		en: 'Enter a last name',
		fr: 'Enter a last name'
	},
	'Gender at birth': {
		en: 'Gender at birth',
		fr: 'Gender at birth'
	},
	'Select your gender at birth': {
		en: 'Select your gender at birth',
		fr: 'Select your gender at birth'
	},
	'Date of birth': {
		en: 'Date of birth',
		fr: 'Date of birth'
	},
	'Enter a date of birth': {
		en: 'Enter a date of birth',
		fr: 'Enter a date of birth'
	},
	'Email address': {
		en: 'Email address',
		fr: 'Email address'
	},
	'Phone number': {
		en: 'Phone number',
		fr: 'Phone number'
	},
	'Phone number is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols.': {
		en: 'Phone number is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols.',
		fr: 'Phone number is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols.'
	},
	'As you are filling this application out on behalf of another, please tell us about yourself.': {
		en: 'As you are filling this application out on behalf of another, please tell us about yourself.',
		fr: 'As you are filling this application out on behalf of another, please tell us about yourself.'
	},
	'What is your relationship to the applicant?': {
		en: 'What is your relationship to the applicant?',
		fr: 'What is your relationship to the applicant?'
	},
	'Select your relationship to applicant.': {
		en: 'Select your relationship to applicant.',
		fr: 'Select your relationship to applicant.'
	},
	'Specify relationship': {
		en: 'Specify relationship',
		fr: 'Specify relationship'
	},
	'Specify your relationship to the applicant.': {
		en: 'Specify your relationship to the applicant.',
		fr: 'Specify your relationship to the applicant.'
	},
	'Canadian Health Card number': {
		en: 'Canadian Health Card number',
		fr: 'Canadian Health Card number'
	},
	'Health Card type or alternate ID': {
		en: 'Health Card type or alternate ID',
		fr: 'Health Card type or alternate ID'
	},
	'Select your Heath Card or alternate ID type': {
		en: 'Select your Heath Card or alternate ID type',
		fr: 'Select your Heath Card or alternate ID type'
	},
	'Health Card number': {
		en: 'Health Card number',
		fr: 'Health Card number'
	},
	'Enter a valid health card number': {
		en: 'Enter a valid health card number',
		fr: 'Enter a valid health card number'
	},
	'Use this service if the applicant:': {
		en: 'Use this service if the applicant:',
		fr: 'Use this service if the applicant:'
	},
	'is a permanent resident of Nova Scotia': {
		en: 'is a permanent resident of Nova Scotia',
		fr: 'is a permanent resident of Nova Scotia'
	},
	'needs to update their Nova Scotia COVID-19 Proof of Vaccination to include their out-of-province vaccinations': {
		en: 'needs to update their Nova Scotia COVID-19 Proof of Vaccination to include their out-of-province vaccinations',
		fr: 'needs to update their Nova Scotia COVID-19 Proof of Vaccination to include their out-of-province vaccinations'
	},
	'is 16 or older': {
		en: 'is 16 or older',
		fr: 'is 16 or older'
	},
	'has received at least 1 dose in Nova Scotia': {
		en: 'has received at least 1 dose in Nova Scotia',
		fr: 'has received at least 1 dose in Nova Scotia'
	},
	'Privacy notice': {
		en: 'Privacy notice',
		fr: 'Privacy notice'
	},
	'The personal information you provide online for the Out of Province Vaccine Entry Service is collected under the authority of sections 8, 15 and 50 of the Health Protection Act (PDF). The submitted personal information via this form is subject to the Department of Health and Wellness and Government of Nova Scotia’s security and privacy policies. The collected personal information will only be used to facilitate eligible users of this service who vaccinated outside Nova Scotia to provide provincially issued proof of vaccination, unless otherwise authorized by law or your express consent. Any secondary use without express consent is limited to aggregate, de-identified information.': {
		en: 'The personal information you provide online for the Out of Province Vaccine Entry Service is collected under the authority of sections 8, 15 and 50 of the Health Protection Act (PDF). The submitted personal information via this form is subject to the Department of Health and Wellness and Government of Nova Scotia’s security and privacy policies. The collected personal information will only be used to facilitate eligible users of this service who vaccinated outside Nova Scotia to provide provincially issued proof of vaccination, unless otherwise authorized by law or your express consent. Any secondary use without express consent is limited to aggregate, de-identified information.',
		fr: 'The personal information you provide online for the Out of Province Vaccine Entry Service is collected under the authority of sections 8, 15 and 50 of the Health Protection Act (PDF). The submitted personal information via this form is subject to the Department of Health and Wellness and Government of Nova Scotia’s security and privacy policies. The collected personal information will only be used to facilitate eligible users of this service who vaccinated outside Nova Scotia to provide provincially issued proof of vaccination, unless otherwise authorized by law or your express consent. Any secondary use without express consent is limited to aggregate, de-identified information.'
	},
	'Are you 16 or older?': {
		en: 'Are you 16 or older?',
		fr: 'Are you 16 or older?'
	},
	'- Select -': {
		en: '- Select -',
		fr: '- Select -'
	},
	'Yes': {
		en: 'Yes',
		fr: 'Yes'
	},
	'No': {
		en: 'No',
		fr: 'No'
	},
	'This is a required field.': {
		en: 'This is a required field.',
		fr: 'This is a required field.'
	},
	'Are you completing this form on behalf of someone else?': {
		en: 'Are you completing this form on behalf of someone else?',
		fr: 'Are you completing this form on behalf of someone else?'
	},
	'Your Information': {
		en: 'Your Information',
		fr: 'Your Information'
	},
	'Continue': {
		en: 'Continue',
		fr: 'Continue'
	},
	'Print a copy': {
		en: 'Print a copy',
		fr: 'Print a copy'
	},
	'Error: File could not be downloaded.': {
		en: 'Error: File could not be downloaded.',
		fr: 'Error: File could not be downloaded.'
	},
	'Save a copy': {
		en: 'Save a copy',
		fr: 'Save a copy'
	},
	'One or more fields are missing or incorrect': {
		en: 'One or more fields are missing or incorrect',
		fr: 'One or more fields are missing or incorrect'
	},
	'Loading': {
		en: 'Loading',
		fr: 'Loading'
	},
	'Which of the following applies to the applicant?': {
		en: 'Which of the following applies to the applicant?',
		fr: 'Which of the following applies to the applicant?'
	},
	'Step {number} of {number}': {
		en: 'Step {number} of {number}',
		fr: 'Step {number} of {number}'
	},
	'Permanent resident of Nova Scotia and received one or more doses in another province or country': {
		en: 'Permanent resident of Nova Scotia and received one or more doses in another province or country',
		fr: 'Permanent resident of Nova Scotia and received one or more doses in another province or country'
	},
	'Permanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)': {
		en: 'Permanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)',
		fr: 'Permanent resident of Nova Scotia and received one or more doses through another vaccination program (federal government, Canadian Armed Forces, etc.)'
	},
	'Recently moved to Nova Scotia (permanently)': {
		en: 'Recently moved to Nova Scotia (permanently)',
		fr: 'Recently moved to Nova Scotia (permanently)'
	},
	'From out-of-province and received one dose in Nova Scotia': {
		en: 'From out-of-province and received one dose in Nova Scotia',
		fr: 'From out-of-province and received one dose in Nova Scotia'
	},
	'From out-of-province and received two doses in my home province or country': {
		en: 'From out-of-province and received two doses in my home province or country',
		fr: 'From out-of-province and received two doses in my home province or country'
	},
	'Visiting Nova Scotia': {
		en: 'Visiting Nova Scotia',
		fr: 'Visiting Nova Scotia'
	},
	'None of the above': {
		en: 'None of the above',
		fr: 'None of the above'
	},
	'Select': {
		en: 'Select',
		fr: 'Select'
	},
	'Proof of Vaccination - Review your information': {
		en: 'Proof of Vaccination - Review your information',
		fr: 'Proof of Vaccination - Review your information'
	},
	'Applicant Information': {
		en: 'Applicant Information',
		fr: 'Applicant Information'
	},
	'Edit': {
		en: 'Edit',
		fr: 'Edit'
	},
	'Applicant ID': {
		en: 'Applicant ID',
		fr: 'Applicant ID'
	},
	'Government ID': {
		en: 'Government ID',
		fr: 'Government ID'
	},
	'Vaccine Information': {
		en: 'Vaccine Information',
		fr: 'Vaccine Information'
	},
	'{ordinal} Dose': { // 1st Dose, 2nd Dose, etc
		en: '{ordinal} Dose',
		fr: '{ordinal} Dose'
	},
	'Where was the dose administered?': {
		en: 'Where was the dose administered?',
		fr: 'Where was the dose administered?'
	},
	'What date was the dose administered?': {
		en: 'What date was the dose administered?',
		fr: 'What date was the dose administered?'
	},
	'What was the name of the vaccine that was administered?': {
		en: 'What was the name of the vaccine that was administered?',
		fr: 'What was the name of the vaccine that was administered?'
	},
	'What was the lot number of the vaccine?': {
		en: 'What was the lot number of the vaccine?',
		fr: 'What was the lot number of the vaccine?'
	},
	'Vaccine Confirmation': {
		en: 'Vaccine Confirmation',
		fr: 'Vaccine Confirmation'
	},
	'Vaccination Record(s)': {
		en: 'Vaccination Record(s)',
		fr: 'Vaccination Record(s)'
	},
	'Confirmation and consent': {
		en: 'Confirmation and consent',
		fr: 'Confirmation and consent'
	},
	'I understand the purpose of this information collection, use and disclosure and I declare that the information given on this form is complete, correct and fully discloses everything concerning my vaccination out of Nova Scotia.': {
		en: 'I understand the purpose of this information collection, use and disclosure and I declare that the information given on this form is complete, correct and fully discloses everything concerning my vaccination out of Nova Scotia.',
		fr: 'I understand the purpose of this information collection, use and disclosure and I declare that the information given on this form is complete, correct and fully discloses everything concerning my vaccination out of Nova Scotia.'
	},
	'I confirm and consent': {
		en: 'I confirm and consent',
		fr: 'I confirm and consent'
	},
	'Submit': {
		en: 'Submit',
		fr: 'Submit'
	},
	'About Your Vaccine and Dose': {
		en: 'About Your Vaccine and Dose',
		fr: 'About Your Vaccine and Dose'
	},
	'Provide the COVID-19 vaccination information received in another province, territory or country or outside a Nova Scotia vaccination clinic (e.g., a community, pharmacy or drive-thru clinic) that should be included on your Nova Scotia COVID-19 Proof of Vaccination.': {
		en: 'Provide the COVID-19 vaccination information received in another province, territory or country or outside a Nova Scotia vaccination clinic (e.g., a community, pharmacy or drive-thru clinic) that should be included on your Nova Scotia COVID-19 Proof of Vaccination.',
		fr: 'Provide the COVID-19 vaccination information received in another province, territory or country or outside a Nova Scotia vaccination clinic (e.g., a community, pharmacy or drive-thru clinic) that should be included on your Nova Scotia COVID-19 Proof of Vaccination.'
	},
	'How many doses have been administered?': {
		en: 'How many doses have been administered?',
		fr: 'How many doses have been administered?'
	},
	'How many doses do you need to add to your Nova Scotia COVID-19 Proof of Vaccination?': {
		en: 'How many doses do you need to add to your Nova Scotia COVID-19 Proof of Vaccination?',
		fr: 'How many doses do you need to add to your Nova Scotia COVID-19 Proof of Vaccination?'
	},
	'Select the number of doses administered': {
		en: 'Select the number of doses administered',
		fr: 'Select the number of doses administered'
	},
	'Applicant’s Information': {
		en: 'Applicant’s Information',
		fr: 'Applicant’s Information'
	},
	'Official government ID details': {
		en: 'Official government ID details',
		fr: 'Official government ID details'
	},
	'Upload 1 image. This can be a photo of an official government ID so we can confirm your details with the information provided above.': {
		en: 'Upload 1 image. This can be a photo of an official government ID so we can confirm your details with the information provided above.',
		fr: 'Upload 1 image. This can be a photo of an official government ID so we can confirm your details with the information provided above.'
	},
	'File to upload:': {
		en: 'File to upload:',
		fr: 'File to upload:'
	},
	'File(s) to upload:': {
		en: 'File(s) to upload:',
		fr: 'File(s) to upload:'
	},
	'Remove File': {
		en: 'Remove File',
		fr: 'Remove File'
	},
	'Accepted file types: *jpg, png, pdf*': {
		en: 'Accepted file types: *jpg, png, pdf*',
		fr: 'Accepted file types: *jpg, png, pdf*'
	},
	'Maximum file size: *{filesize}*': {
		en: 'Maximum file size: *{filesize}*',
		fr: 'Maximum file size: *{filesize}*'
	},
	'File upload is required.': {
		en: 'File upload is required.',
		fr: 'File upload is required.'
	},
	'Incorrect file type.': {
		en: 'Incorrect file type.',
		fr: 'Incorrect file type.'
	},
	'Some files exceed the 5MB limit.': {
		en: 'Some files exceed the 5MB limit.',
		fr: 'Some files exceed the 5MB limit.'
	},
	'Some file names exceed the 75 character limit.': {
		en: 'Some file names exceed the 75 character limit.',
		fr: 'Some file names exceed the 75 character limit.'
	},
	'Upload your vaccine receipt(s)': {
		en: 'Upload your vaccine receipt(s)',
		fr: 'Upload your vaccine receipt(s)'
	},
	'This can be a screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt.': {
		en: 'This can be a screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt.',
		fr: 'This can be a screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt.'
	},
	'Select the location of administration': {
		en: 'Select the location of administration',
		fr: 'Select the location of administration'
	},
	'Select the name of the vaccine administered': {
		en: 'Select the name of the vaccine administered',
		fr: 'Select the name of the vaccine administered'
	},
	'What was the lot number of the vaccine? (optional)': {
		en: 'What was the lot number of the vaccine? (optional)',
		fr: 'What was the lot number of the vaccine? (optional)'
	},
	'Date of birth day': {
		en: 'Date of birth day',
		fr: 'Date of birth day'
	},
	'Date of birth month': {
		en: 'Date of birth month',
		fr: 'Date of birth month'
	},
	'Date of birth year': {
		en: 'Date of birth year',
		fr: 'Date of birth year'
	},
	'Error: Application could not be submitted. Please try again later.': {
		en: 'Error: Application could not be submitted. Please try again later.',
		fr: 'Error: Application could not be submitted. Please try again later.'
	},
	'Error: Reference number not found. Please contact an admin.': {
		en: 'Error: Reference number not found. Please contact an admin.',
		fr: 'Error: Reference number not found. Please contact an admin.'
	},
	'Out-of-Province COVID-19 Vaccine Entry Service - Get Started': {
		en: 'Out-of-Province COVID-19 Vaccine Entry Service - Get Started',
		fr: 'Out-of-Province COVID-19 Vaccine Entry Service - Get Started'
	},
	'Out-of-Province COVID-19 Vaccine Entry Service - Update Proof of Vaccination - Step {number} of {number}': {
		en: 'Out-of-Province COVID-19 Vaccine Entry Service - Update Proof of Vaccination - Step {number} of {number}',
		fr: 'Out-of-Province COVID-19 Vaccine Entry Service - Update Proof of Vaccination - Step {number} of {number}'
	},
	'Out-of-Province COVID-19 Vaccine Entry Service - Update Proof of Vaccination - Review': {
		en: 'Out-of-Province COVID-19 Vaccine Entry Service - Update Proof of Vaccination - Review',
		fr: 'Out-of-Province COVID-19 Vaccine Entry Service - Update Proof of Vaccination - Review'
	},
	'Out-of-Province COVID-19 Vaccine Entry Service - Success': {
		en: 'Out-of-Province COVID-19 Vaccine Entry Service - Success',
		fr: 'Out-of-Province COVID-19 Vaccine Entry Service - Success'
	},
	'Out-of-Province COVID-19 Vaccine Entry Service - Not Found': {
		en: 'Out-of-Province COVID-19 Vaccine Entry Service - Not Found',
		fr: 'Out-of-Province COVID-19 Vaccine Entry Service - Not Found'
	},
	'We have emailed you a confirmation of your submission.': {
		en: 'We have emailed you a confirmation of your submission.',
		fr: 'We have emailed you a confirmation of your submission.'
	},
	'If you are completing this form on behalf of another individual over the age of 16, please confirm by checking this box that you have the consent of the applicant for submitting their information.': {
		en: 'If you are completing this form on behalf of another individual over the age of 16, please confirm by checking this box that you have the consent of the applicant for submitting their information.',
		fr: 'If you are completing this form on behalf of another individual over the age of 16, please confirm by checking this box that you have the consent of the applicant for submitting their information.'
	},
	'An official proof of the following WHO authorized COVID-19 vaccines:': {
		en: 'An official proof of the following WHO authorized COVID-19 vaccines:',
		fr: 'An official proof of the following WHO authorized COVID-19 vaccines:'
	},
	'This service is available to the permanent residents of Nova Scotia or people who received at least 1 dose of COVID-19 vaccine in Nova Scotia.': {
		en: 'This service is available to the permanent residents of Nova Scotia or people who received at least 1 dose of COVID-19 vaccine in Nova Scotia.',
		fr: 'This service is available to the permanent residents of Nova Scotia or people who received at least 1 dose of COVID-19 vaccine in Nova Scotia.'
	},
	'Select your Health Card type. If you do not have a Canadian Health Card, select one of the other options that best applies to you.': {
		en: 'Select your Health Card type. If you do not have a Canadian Health Card, select one of the other options that best applies to you.',
		fr: 'Select your Health Card type. If you do not have a Canadian Health Card, select one of the other options that best applies to you.'
	},
	'Nova Scotians can now add COVID-19 vaccines that they received outside the province or through a workplace vaccination program to their Nova Scotia proof of vaccination.': {
		en: 'Nova Scotians can now add COVID-19 vaccines that they received outside the province or through a workplace vaccination program to their Nova Scotia proof of vaccination.',
		fr: 'Nova Scotians can now add COVID-19 vaccines that they received outside the province or through a workplace vaccination program to their Nova Scotia proof of vaccination.'
	},
	'The opportunity is available to:': {
		en: 'The opportunity is available to:',
		fr: 'The opportunity is available to:'
	},
	'permanent residents of Nova Scotia who received one or both doses in another province, territory or country': {
		en: 'permanent residents of Nova Scotia who received one or both doses in another province, territory or country',
		fr: 'permanent residents of Nova Scotia who received one or both doses in another province, territory or country'
	},
	'permanent residents who received one or both doses in a workplace vaccination program, such as members of the Canadian Armed Forces': {
		en: 'permanent residents who received one or both doses in a workplace vaccination program, such as members of the Canadian Armed Forces',
		fr: 'permanent residents who received one or both doses in a workplace vaccination program, such as members of the Canadian Armed Forces'
	},
	'Ensure the uploads include all the vaccine details that are included on your receipts.': {
		en: 'Ensure the uploads include all the vaccine details that are included on your receipts.',
		fr: 'Ensure the uploads include all the vaccine details that are included on your receipts.'
	},
	'Your application has been submitted. It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.': {
		en: 'Your application has been submitted. It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.',
		fr: 'Your application has been submitted. It will take up to 3 weeks to update your Nova Scotia COVID-19 Proof of Vaccination. It can take longer if more information is needed. You will need to restart the process if more documentation is required.'
	},
	'Birth Certificate': {
		en: 'Birth Certificate',
		fr: 'Birth Certificate'
	},
	'people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card': {
		en: 'people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card',
		fr: 'people who need to receive a booster dose in Nova Scotia and do not have a Nova Scotia health card'
	},
	'From out-of-province and need to schedule a booster dose while in Nova Scotia': {
		en: 'From out-of-province and need to schedule a booster dose while in Nova Scotia',
		fr: 'From out-of-province and need to schedule a booster dose while in Nova Scotia'
	},
	'Nuvaxovid/Novavax': {
		en: 'Nuvaxovid/Novavax',
		fr: 'Nuvaxovid/Novavax'
	},
	'Covovax/Novavax': {
		en: 'Covovax/Novavax',
		fr: 'Covovax/Novavax'
	},
	'Pfizer-BioNTech Pediatric': {
		en: 'Pfizer-BioNTech Pediatric',
		fr: 'Pfizer-BioNTech Pediatric'
	},
	'Moderna Bivalent BA.1': {
		en: 'Moderna Bivalent BA.1',
		fr: 'Moderna Bivalent BA.1'
	},
	'Moderna Bivalent BA.4/.5': {
		en: 'Moderna Bivalent BA.4/.5',
		fr: 'Moderna Bivalent BA.4/.5'
	},
	'Pfizer Bivalent BA.4/.5': {
		en: 'Pfizer Bivalent BA.4/.5',
		fr: 'Pfizer Bivalent BA.4/.5'
	},
	'Pfizer Bivalent BA.4/.5 Pediatric': {
		en: 'Pfizer Bivalent BA.4/.5 Pediatric',
		fr: 'Pfizer Bivalent BA.4/.5 Pediatric'
	},
	'Pfizer Comirnaty (6 mths to 4 yrs)': {
		en: 'Pfizer Comirnaty (6 mths to 4 yrs)',
		fr: 'Pfizer Comirnaty (6 mths to 4 yrs)'
	}
};
