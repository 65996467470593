import { applicationConstants } from '../constants/applicationConstants';
import { applicationService } from '../../services/applicationService';

export const applicationActions = {
	currentApplicationUpdate,
	currentApplicationReset,
	postApplication,
	postApplicationReset,
	applicationReview,
	applicationReviewReset
};

function currentApplicationUpdate(data) {
	if (data && typeof data === 'object') {
		return {
			type: applicationConstants.CURRENT_APPLICATION_UPDATE,
			data
		};
	}
}

function currentApplicationReset() {
	return {
		type: applicationConstants.CURRENT_APPLICATION_RESET
	};
}

function postApplication(data) {
	return dispatch => {
		dispatch(request());

		applicationService.post(data)
			.then(
				response => {
					if (response && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: applicationConstants.APPLICATION_POST_REQUEST }; }

	function success(data) { return { type: applicationConstants.APPLICATION_POST_SUCCESS, data: data }; }

	function error() { return { type: applicationConstants.APPLICATION_POST_ERROR }; }
}

function postApplicationReset() {
	return {
		type: applicationConstants.APPLICATION_POST_RESET
	};
}

function applicationReview() {
	return {
		type: applicationConstants.APPLICATION_REVIEW
	};
}

function applicationReviewReset() {
	return {
		type: applicationConstants.APPLICATION_REVIEW_RESET
	};
}
