import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-translated';
import unorm from 'unorm';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';
import { fileToBase64, bytesToSize } from '../../helpers/fileHelpers';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function UploadGovID(props) {
	const { validated } = props;
	const {
		DOC_TYPE_GOV_ID,
		DOC_CONTENT_TYPE_PDF,
		DOC_CONTENT_TYPE_JPEG,
		DOC_CONTENT_TYPE_PNG,
		DOC_MIME_TYPE_PDF,
		DOC_MIME_TYPE_JPEG,
		DOC_MIME_TYPE_PNG,
		DOC_MIME_TYPES,
		DOC_MAX_FILESIZE,
		DOC_MAX_FILENAME
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const buttonTextDefault = 'Upload File';
	const [buttonText, setButtonText] = useState(buttonTextDefault);
	const [uploads, setUploads] = useState(null);
	const [uploadsError, setUploadsError] = useState('');
	const [typeError, setTypeError] = useState('');
	const [filesizeError, setFilesizeError] = useState('');
	const [filenameError, setFilenameError] = useState('');
	const [typeFiles, setTypeFiles] = useState([]);
	const [filesizeFiles, setFilesizeFiles] = useState([]);
	const [filenameFiles, setFilenameFiles] = useState([]);
	const filesizeString = bytesToSize(DOC_MAX_FILESIZE);
	const fileElement = useRef(null);
	const mimeTypes = DOC_MIME_TYPES;

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const currentApplication = useSelector(state => state.currentApplication);

	useEffect(() => {
		if (currentApplication && currentApplication.vaccinationDocumentList) {
			let currentUploads = [...currentApplication.vaccinationDocumentList];
			currentUploads = currentUploads.filter(item => item && item.documentType == DOC_TYPE_GOV_ID);

			if (currentUploads.length) {
				setUploads(currentUploads);
				setButtonText(currentUploads[0].filename);
			}
		}
	}, [currentApplication]);

	const processFiles = (fileList) => {
		let files;
		let filesArray = [];
		setUploads(null);
		setUploadsError('');
		setTypeError('');
		setFilesizeError('');
		setFilenameError('');
		fileElement.current.setCustomValidity('');
		setTypeFiles([]);
		setFilesizeFiles([]);
		setFilenameFiles([]);

		files = [...fileList];

		files.map((file, index) => {
			let contentType;

			if (Array.isArray(mimeTypes) && !mimeTypes.includes(file.type)) {
				setTypeError('Incorrect file type.');
				fileElement.current.setCustomValidity('Incorrect file type.');
				setTypeFiles(typeFiles => [...typeFiles, file.name]);
				return false;
			}

			if (file.size > DOC_MAX_FILESIZE) {
				setFilesizeError(`Some files exceed the ${filesizeString} limit.`);
				fileElement.current.setCustomValidity(`Some files exceed the ${filesizeString} limit.`);
				setFilesizeFiles(filesizeFiles => [...filesizeFiles, file.name]);
				return false;
			}

			if (file.name.length > DOC_MAX_FILENAME) {
				setFilenameError(`Some file names exceed the ${DOC_MAX_FILENAME} character limit.`);
				fileElement.current.setCustomValidity(`Some file names exceed the ${DOC_MAX_FILENAME} character limit.`);
				setFilenameFiles(filenameFiles => [...filenameFiles, file.name]);
				return false;
			}

			switch (file.type) {
				case DOC_MIME_TYPE_PDF:
					contentType = DOC_CONTENT_TYPE_PDF;
					break;
				case DOC_MIME_TYPE_JPEG:
					contentType = DOC_CONTENT_TYPE_JPEG;
					break;
				case DOC_MIME_TYPE_PNG:
					contentType = DOC_CONTENT_TYPE_PNG;
					break;
				default:
					break;
			}

			// Convert to Base64 & add to array.
			fileToBase64(file)
				.then((raw) => {
					const data = raw.split(',')[1];

					if (data && file) {
						let filenameExt = file.name.split('.').pop();
						let filename = file.name.split('.').slice(0, -1).join('.');

						if (filename) {
							filename = filename.trim() + '-ID.' + filenameExt;
						} else {
							filename = file.name;
						}

						const upload = {
							changeType: 'SAVE',
							filename: unorm.nfc(filename),
							documentContentType: contentType,
							documentType: DOC_TYPE_GOV_ID,
							data: data
						};

						filesArray.push(upload);

						const newFiles = [upload];
						let existingFiles = (currentApplication.vaccinationDocumentList) ? currentApplication.vaccinationDocumentList : [];
						existingFiles = existingFiles.filter(item => item.documentType !== DOC_TYPE_GOV_ID); // remove any other ones that may exist.
						const updatedFiles = existingFiles.concat(newFiles);

						const updatedData = {
							...currentApplication,
							vaccinationDocumentList: updatedFiles
						};

						dispatch(applicationActions.currentApplicationUpdate(updatedData));
					}
				})
				.catch((error) => {
					console.log(error); // eslint-disable-line
					return false;
				});

			if (file && index == 0) {
				setButtonText(file.name);
			} else {
				setButtonText(buttonTextDefault);
			}

			return file;
		});

		if (typeError == '' && filesizeError == '' && filenameError == '') {
			setUploads(filesArray);
		}
	};

	const handleUpdate = () => {
		const fileList = fileElement.current.files;
		processFiles(fileList);

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleRemoveFile = () => {
		fileElement.current.value = '';
		setUploads(null);
		setButtonText(buttonTextDefault);
		fileElement.current.setCustomValidity('');

		let existingFiles = (currentApplication.vaccinationDocumentList) ? currentApplication.vaccinationDocumentList : [];
		const originalFiles = existingFiles.filter(item => item.documentType !== DOC_TYPE_GOV_ID); // remove any other ones that may exist.

		const updatedData = {
			...currentApplication,
			vaccinationDocumentList: originalFiles
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));
	};

	return (
		<>
			<h3><Translate text="Official government ID details" /><span className="required">*</span></h3>
			<Form.Group controlId="govId" className="form-custom-file">
				<p><Translate text="Upload 1 image. This can be a photo of an official government ID so we can confirm your details with the information provided above." /></p>
				<h4>
					<Translate text="File to upload:" />

					{ (uploads && uploads.length > 0) &&
						<Button
							variant="link"
							className="text-danger ml-2"
							onClick={ handleRemoveFile }
						>
							<Translate text="Remove File" />
						</Button>
					}
				</h4>
				<Form.Control
					ref={ fileElement }
					type="file"
					className="visuallyhidden"
					aria-describedby="upload-files-help-text"
					required={ !uploads }
					accept={ (Array.isArray(mimeTypes)) ? mimeTypes.toString() : null }
					isInvalid={ !uploads || !uploads.length || typeError !== '' || filesizeError !== '' || filenameError !== '' }
					isValid={ uploads && uploads.length && typeError == '' && filesizeError == '' && filenameError == '' }
					onChange={() => { handleUpdate(); }}
				/>
				<Form.Label>
					<Translate text={ buttonText } />

					{ (uploads && uploads.length > 0) &&
						<FontAwesomeIcon icon={ faCheckCircle } />
					}
				</Form.Label>

				<div id="upload-files-help-text">
					<p>
						<Translate text="Accepted file types: *jpg, png, pdf*" /><br />
						<Translate
							text="Maximum file size: *{filesize}*"
							data={{
								filesize: filesizeString
							}}
						/>
					</p>
				</div>

				<div className={`invalid-feedback error-gov-id-feedback ${(uploadsError || typeError || filesizeError || filenameError) ? 'show' : ''}`}>
					<div><Translate text={ uploadsError } /></div>

					<div><Translate text={ typeError } /></div>
					{ typeFiles && (typeFiles.length > 0) &&
						<ul>
							{
								typeFiles.map((file, index) => {
									return (
										<li key={ index }>
											{ file }
										</li>
									);
								})
							}
						</ul>
					}

					<div><Translate text={ filesizeError } /></div>
					{ filesizeFiles && (filesizeFiles.length > 0) &&
						<ul>
							{
								filesizeFiles.map((file, index) => {
									return (
										<li key={ index }>
											{ file }
										</li>
									);
								})
							}
						</ul>
					}

					<div><Translate text={ filenameError } /></div>
					{ filenameFiles && (filenameFiles.length > 0) &&
						<ul>
							{
								filenameFiles.map((file, index) => {
									return (
										<li key={ index }>
											{ file }
										</li>
									);
								})
							}
						</ul>
					}
				</div>

				{ validated && !uploads &&
					<div className="invalid-feedback show error-gov-id-upload">
						<Translate text="File upload is required." />
					</div>
				}
			</Form.Group>
		</>
	);
}

export default UploadGovID;
