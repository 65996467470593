import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import { applicationActions } from '../../store/actions/applicationActions';
import { bytesToSize } from '../../helpers/fileHelpers';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import UploadVaccineConfirm from './UploadVaccineConfirm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function VaccineConfirm(props) {
	const { validated } = props;
	const {
		DOC_MAX_FILESIZE,
		DOC_MAX_QUANTITY,
		DOC_TYPE_VACCINE_CONFIRM
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const [uploads, setUploads] = useState([{ documentType: DOC_TYPE_VACCINE_CONFIRM }]);
	const [hasDuplicates, setHasDuplicates] = useState(false);
	const filesizeString = bytesToSize(DOC_MAX_FILESIZE);

	// State data.
	const currentApplication = useSelector(state => state.currentApplication);

	useEffect(() => {
		if (currentApplication && currentApplication.vaccinationDocumentList) {
			let currentUploads = [...currentApplication.vaccinationDocumentList];
			currentUploads = currentUploads.filter(item => item && item.documentType == DOC_TYPE_VACCINE_CONFIRM);

			if (currentUploads.length) {
				setUploads(currentUploads);

				// Checking if uploads contain items with the same data, ie. the same file.
				const dataArray = currentUploads.map(item => { return item.data; });
				const isDuplicate = dataArray.some((item, index) => { return dataArray.indexOf(item) !== index; });
				setHasDuplicates(isDuplicate);
			} else {
				setUploads([{ documentType: DOC_TYPE_VACCINE_CONFIRM }]);
			}
		}
	}, [currentApplication]);

	const handleAddFile = () => {
		let otherUploads = (Array.isArray(currentApplication.vaccinationDocumentList)) ? [...currentApplication.vaccinationDocumentList] : [];
		otherUploads = otherUploads.filter(item => item && item.documentType !== DOC_TYPE_VACCINE_CONFIRM);

		let items = [...uploads];
		items.push({ documentType: DOC_TYPE_VACCINE_CONFIRM });

		const updatedFiles = otherUploads.concat(items);

		const updatedData = {
			...currentApplication,
			vaccinationDocumentList: updatedFiles
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));
	};

	return (
		<>
			<h3><Translate text="Upload your vaccine receipt(s)" /><span className="required">*</span></h3>
			<Form.Group className="form-custom-file">
				<p><Translate text="This can be a screenshot of your proof of vaccination receipt or a scanned image or photo of your paper vaccine receipt." /></p>
				<p><strong><Translate text="Ensure the uploads include all the vaccine details that are included on your receipts." /></strong></p>
				<h4><Translate text="File(s) to upload:" /></h4>

				{ uploads.map((item, index) => {
					return (
						<div className="upload-item" key={ index }>
							<UploadVaccineConfirm
								item={ item }
								index={ index }
								validated={ validated }
							/>
						</div>
					);
				})}

				{ (uploads && uploads.length < DOC_MAX_QUANTITY) &&
					<div className="upload-controls">
						<Button
							variant="link"
							onClick={ handleAddFile }
						>
							<Translate text="+ Add File" />
						</Button>
					</div>
				}

				{ hasDuplicates &&
					<Alert>
						<span className="alert-icon">
							<FontAwesomeIcon icon={ faInfoCircle } />
						</span>
						<span className="alert-message">
							<Translate text="Uploads may contain duplicates. Please confirm you have not included the same file more than once." />
						</span>
					</Alert>
				}

				<div id="upload-vaccine-confirm-help-text">
					<p>
						<Translate text="Accepted file types: *jpg, png, pdf*" /><br />
						<Translate
							text="Up to *{maxQuantity} files* can be selected at once."
							data={{
								maxQuantity: DOC_MAX_QUANTITY
							}}
						/><br />
						<Translate
							text="Maximum file size: *{filesize}*"
							data={{
								filesize: filesizeString
							}}
						/>
					</p>
				</div>
			</Form.Group>
		</>
	);
}

export default VaccineConfirm;
