import axios from 'axios';
import { authHeader } from '../helpers/authHeader';
const env = window.env;

export const applicationService = {
	post
};

function post(data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/application`,
		data: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return {};
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line
	return {error: 'Error: Network Error'};
}
