import React, { useState, useEffect, useRef } from 'react';
import { Translate, Translator } from 'react-translated';
import NumberFormat from 'react-number-format';
import { format, parseISO, isValid, isPast, isAfter, isSameDay } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

export default function FormDate(props) {
	const {
		id,
		name,
		value,
		label,
		required,
		disabled,
		validated,
		customError,
		minDate
	} = props;

	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [validityChecked, setValidityChecked] = useState(false);
	const [invalidDate, setInvalidDate] = useState(false);
	const labelText = (label) ? label : 'Date';
	const errorMessage = (customError) ? customError : 'Date is required.';
	const earliest = (minDate) ? minDate : new Date(1900, 0, 1);
	const containerRef = useRef(null);
	const dayRef = useRef(null);
	const monthRef = useRef(null);
	const yearRef = useRef(null);
	const monthOptions = [
		{ value: '01', name: 'Jan' },
		{ value: '02', name: 'Feb' },
		{ value: '03', name: 'Mar' },
		{ value: '04', name: 'Apr' },
		{ value: '05', name: 'May' },
		{ value: '06', name: 'Jun' },
		{ value: '07', name: 'Jul' },
		{ value: '08', name: 'Aug' },
		{ value: '09', name: 'Sep' },
		{ value: '10', name: 'Oct' },
		{ value: '11', name: 'Nov' },
		{ value: '12', name: 'Dec' }
	];

	useEffect(() => {
		if (value) {
			const date = parseISO(value);

			if (isValid(date)) {
				setDay(format(date, 'dd'));
				setMonth(format(date, 'MM'));
				setYear(format(date, 'yyyy'));
			}
		}
	}, [value]);

	useEffect(() => {
		if (day && month && year) {
			const newValue = year + '-' + month + '-' + day;

			if (
				isValid(parseISO(newValue)) &&
				isPast(parseISO(newValue)) &&
				(isAfter(parseISO(newValue), earliest) || isSameDay(parseISO(newValue), earliest)) &&
				props.handleOnChange
			) {
				props.handleOnChange(id, newValue);
				setInvalidDate(false);
				containerRef.current.classList.remove('was-validated');
				dayRef.current.setCustomValidity('');
				monthRef.current.setCustomValidity('');
				yearRef.current.setCustomValidity('');

				const fields = containerRef.current.getElementsByClassName('form-group');

				if (fields) {
					for (var i = 0; i < fields.length; i++) {
						fields[i].classList.remove('was-validated');
					}
				}
			} else if (props.handleOnChange) {
				props.handleOnChange(id, '');
				setInvalidDate(true);
			}
		}
	}, [day, month, year]);

	const handleCheckValidity = (event, fieldName) => {
		const { value } = event.target;

		event.target.closest('.form-group').classList.remove('was-validated');
		event.target.setCustomValidity('');

		if (fieldName == 'day' && value && value.length == 1) {
			setDay('0' + value);
			return;
		}

		if (invalidDate) {
			event.target.closest('.form-date').classList.add('was-validated');
			event.target.closest('.form-group').classList.add('was-validated');
			dayRef.current.setCustomValidity(customError);
			monthRef.current.setCustomValidity(customError);
			yearRef.current.setCustomValidity(customError);
			setValidityChecked(true);
		} else {
			event.target.closest('.form-date').classList.remove('was-validated');
			event.target.closest('.form-group').classList.remove('was-validated');
			dayRef.current.setCustomValidity('');
			monthRef.current.setCustomValidity('');
			yearRef.current.setCustomValidity('');
			setValidityChecked(false);
		}
	};

	const handleChangeDay = (event) => {
		const { value } = event.target;
		setDay(value);
	};

	const handleChangeMonth = (event) => {
		const { value } = event.target;
		setMonth(value);
	};

	const handleChangeYear = (event) => {
		const { value } = event.target;
		setYear(value);
	};

	return (
		<Translator>
			{({ translate }) => (
				<div className="form-date" ref={ containerRef }>
					<h4>
						{ translate({ text: labelText }) }

						{ required &&
							<span className="required">*</span>
						}
					</h4>

					<Row>
						<Col>
							<Form.Group controlId={ id + '-day' }>
								<Form.Label>
									{ translate({ text: 'DD' }) }
								</Form.Label>

								<NumberFormat
									getInputRef={ dayRef }
									value={ (day) ? day : '' }
									customInput={ Form.Control }
									type="text"
									name={ name + '-day' }
									aria-label={ translate({ text: `${labelText} day` }) }
									maxLength={ 2 }
									pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])"
									inputMode="numeric"
									allowNegative={ false }
									allowLeadingZeros={ true }
									isNumericString={ true }
									required={ (required) ? true : null }
									disabled={ disabled }
									onChange={ handleChangeDay }
									onBlur={(e) => { handleCheckValidity(e, 'day'); }}
								/>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId={ id + '-month' }>
								<Form.Label>
									{ translate({ text: 'MM' }) }
								</Form.Label>

								<Form.Control
									ref={ monthRef }
									as="select"
									name={ name + '-month' }
									aria-label={ translate({ text: `${labelText} month` }) }
									value={ (month) ? month : '' }
									required={ (required) ? true : null }
									disabled={ disabled }
									onChange={ handleChangeMonth }
									onBlur={(e) => { handleCheckValidity(e, 'month'); }}
								>
									<option value=""></option>

									{ (Array.isArray(monthOptions)) &&
										monthOptions.map((item, index) => {
											return (
												<option value={ item.value } key={ index }>
													{ item.name }
												</option>
											);
										})
									}
								</Form.Control>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId={ id + '-year' }>
								<Form.Label>
									{ translate({ text: 'YYYY' }) }
								</Form.Label>

								<NumberFormat
									getInputRef={ yearRef }
									value={ (year) ? year : '' }
									customInput={ Form.Control }
									type="text"
									name={ name + '-year' }
									aria-label={ translate({ text: `${labelText} year` }) }
									maxLength={ 4 }
									pattern={ (invalidDate) ? '' : '[0-2][0-9][0-9][0-9]' }
									inputMode="numeric"
									allowNegative={ false }
									allowLeadingZeros={ true }
									isNumericString={ true }
									required={ (required) ? true : null }
									isInvalid={ ((validated || validityChecked) && invalidDate) }
									disabled={ disabled }
									onChange={ handleChangeYear }
									onBlur={(e) => { handleCheckValidity(e, 'year'); }}
								/>
							</Form.Group>
						</Col>
					</Row>

					{ required && (validated || validityChecked) && (!day || !month || !year || invalidDate) &&
						<div className="invalid-feedback show error-date">
							<Translate text={ errorMessage } />
						</div>
					}
				</div>
			)}
		</Translator>
	);
}
