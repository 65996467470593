import React from 'react';
import { Translate } from 'react-translated';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

function Footer() {
	return (
		<Container as="footer" fluid={ true } className="footer">
			<Nav>
				<Nav.Item>
					<Nav.Link
						href="https://beta.novascotia.ca/privacy/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Translate text="Privacy" />
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link
						href="https://beta.novascotia.ca/terms/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Translate text="Terms" />
					</Nav.Link>
				</Nav.Item>
			</Nav>
			<Nav>
				<Nav.Item>
					<Nav.Link
						href="https://beta.novascotia.ca/copyright/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Translate text="Crown copyright &copy; Government of Nova Scotia" />
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</Container>
	);
}

export default Footer;
