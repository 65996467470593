import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { applicationActions } from '../../store/actions/applicationActions';
import { getNumberWithOrdinal } from '../../helpers/miscHelpers';

import Form from 'react-bootstrap/Form';
import FormDate from '../forms/FormDate';

function VaccineAndDose(props) {
	const {
		index,
		validated,
		multipleDoses
	} = props;
	const dispatch = useDispatch();

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);
	const referenceData = useSelector(state => state.referenceData);
	const currentApplication = useSelector(state => state.currentApplication);

	const [locationOptions, setLocationOptions] = useState([]);
	const [vaccineOptions, setVaccineOptions] = useState([]);

	const doseCountry = (currentApplication.doseAdministeredDataList && currentApplication.doseAdministeredDataList[index]) ? currentApplication.doseAdministeredDataList[index].doseCountry : null;
	const doseDate = (currentApplication.doseAdministeredDataList && currentApplication.doseAdministeredDataList[index]) ? currentApplication.doseAdministeredDataList[index].doseDate : null;
	const vaccineName = (currentApplication.doseAdministeredDataList && currentApplication.doseAdministeredDataList[index]) ? currentApplication.doseAdministeredDataList[index].vaccineName : null;
	const lotNumber = (currentApplication.doseAdministeredDataList && currentApplication.doseAdministeredDataList[index]) ? currentApplication.doseAdministeredDataList[index].lotNumber : null;

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data && referenceData.success) {
			let locations = [];

			if (Array.isArray(referenceData.data.provinceStateList)) {
				locations = locations.concat(referenceData.data.provinceStateList);
			}

			if (Array.isArray(referenceData.data.countryDataList)) {
				locations = locations.concat(referenceData.data.countryDataList);
			}

			setLocationOptions(locations);

			if (referenceData.data.vaccineNameDataList) {
				referenceData.data.vaccineNameDataList.sort(function(firstEl, secondEl) {
					var textA = firstEl.description.toUpperCase().trim();
					var textB = secondEl.description.toUpperCase().trim();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				});
				setVaccineOptions(referenceData.data.vaccineNameDataList);
			}
		}
	}, [referenceData]);

	const handleCheckValidity = (event) => {
		if (!event.target.checkValidity()) {
			event.target.closest('.form-group').classList.add('was-validated');
		} else {
			event.target.closest('.form-group').classList.remove('was-validated');
		}
	};

	const handleUpdate = (id, value) => {
		let items = (Array.isArray(currentApplication.doseAdministeredDataList)) ? [...currentApplication.doseAdministeredDataList] : [{}];

		if (value !== '') {
			items[index][id] = value;
		} else {
			items[index][id] = '';
		}

		const updatedData = {
			...currentApplication,
			doseAdministeredDataList: items
		};

		dispatch(applicationActions.currentApplicationUpdate(updatedData));

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	return (
		<Translator>
			{({ translate }) => (
				<fieldset>
					<legend className={ (!multipleDoses) ? 'visuallyhidden' : null }>
						<Translate
							text="{ordinal} Dose"
							data={{
								ordinal: getNumberWithOrdinal(index + 1)
							}}
						/>
					</legend>

					<Form.Group controlId={`doseCountry-${index}`}>
						<Form.Label>
							<Translate text="Where was the dose administered?" /><span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="doseCountry"
							value={ (doseCountry) ? doseCountry : '' }
							required
							disabled={ !locationOptions || !locationOptions.length }
							onChange={(e) => { handleUpdate(e.target.name, e.target.value); }}
							onBlur={(e) => { handleCheckValidity(e); }}
						>
							<option value="">
								{ translate({ text: '- Select -' }) }
							</option>

							{ (Array.isArray(locationOptions)) &&
								locationOptions.map((item, index) => {
									const itemName = (item.nameEn) ? item.nameEn : (item.name) ? item.name : null;

									if (itemName) {
										return (
											<option value={ itemName } key={ index }>
												{ itemName }
											</option>
										);
									}

									return null;
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid" className="error-dose-location">
							<Translate text="Select the location of administration" />
						</Form.Control.Feedback>
					</Form.Group>

					<FormDate
						id={`doseDate-${index}`}
						name="doseDate"
						label={ translate({ text: 'What date was the dose administered?' }) }
						value={ doseDate }
						required={ true }
						validated={ validated }
						minDate={ new Date(2020, 0, 1) }
						handleOnChange={(id, value) => handleUpdate('doseDate', value) }
					/>

					<Form.Group controlId={`vaccineName-${index}`}>
						<Form.Label>
							<Translate text="What was the name of the vaccine that was administered?" /><span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="vaccineName"
							value={ (vaccineName) ? vaccineName : '' }
							required
							disabled={ !vaccineOptions || !vaccineOptions.length }
							onChange={(e) => { handleUpdate(e.target.name, e.target.value); }}
							onBlur={(e) => { handleCheckValidity(e); }}
						>
							<option value="">
								{ translate({ text: '- Select -' }) }
							</option>

							{ (Array.isArray(vaccineOptions)) &&
								vaccineOptions.map((item, index) => {
									return (
										<option value={ item.description } key={ index }>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid" className="error-vaccine-name">
							<Translate text="Select the name of the vaccine administered" />
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId={`lotNumber-${index}`}>
						<Form.Label>
							<Translate text="What was the lot number of the vaccine? (optional)" />
						</Form.Label>
						<Form.Control
							type="text"
							name="lotNumber"
							defaultValue={ (lotNumber) ? lotNumber : '' }
							maxLength={ 20 }
							onBlur={(e) => { handleUpdate(e.target.name, e.target.value); }}
						/>
					</Form.Group>

					<hr />
				</fieldset>
			)}
		</Translator>
	);
}

export default VaccineAndDose;
