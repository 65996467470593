export const applicationConstants = {
	CURRENT_APPLICATION_UPDATE: 'CURRENT_APPLICATION_UPDATE',
	CURRENT_APPLICATION_RESET: 'CURRENT_APPLICATION_RESET',
	APPLICATION_POST_REQUEST: 'APPLICATION_POST_REQUEST',
	APPLICATION_POST_SUCCESS: 'APPLICATION_POST_SUCCESS',
	APPLICATION_POST_ERROR: 'APPLICATION_POST_ERROR',
	APPLICATION_POST_RESET: 'APPLICATION_POST_RESET',
	APPLICATION_REVIEW: 'APPLICATION_REVIEW',
	APPLICATION_REVIEW_RESET: 'APPLICATION_REVIEW_RESET'
};
